@import "../../styles/variables.scss";

.iconlist {
    list-style-type: none;
    padding-top: 2rem;
    li {
        display: flex;
        flex-direction: row;
        margin-top: 1rem;
        margin-bottom: 1rem;
        align-items: center;
        font-weight: 300;
        &:before {
            content: "";
            display: block;
            border-radius: $default-panel-radius-small;
            width: 40px;
            height: 40px;
            margin-right: 1rem;
            flex-shrink: 0;
            background-repeat: no-repeat;
            background-position: center;
        }

        &.pointer {
            padding-left: 0;
            height: max-content;
            &:before {
                background-image: url("/assets/icons/pointer.svg");
            }
        }

        &.contract {
            &:before {
                background-image: url("/assets/icons/contract.svg");
            }
        }

        &.price {
            &:before {
                background-image: url("/assets/icons/pricetag.svg");
            }
        }

        &.procedure {
            &:before {
                background-image: url("/assets/icons/procedure.svg");
            }
        }

        &.lightbulb {
            &:before {
                background-image: url("/assets/icons/lightbulb.svg");
            }
        }

        &.check {
            &:before {
                background-image: url("/assets/icons/vink.svg");
            }
        }

        &.default {
            &:before {
            }
        }

        &.colors-blue {
            &:before {
                background-color: $primary-blue;
            }
        }
        &.colors-lightblue {
            &:before {
                background-color: $theme-nieuwetechnologie;
            }
        }
        &.colors-pink {
            &:before {
                background-color: $theme-openbareruimte;
            }
        }
        &.colors-purple {
            &:before {
                background-color: $theme-onderwijs;
            }
        }

        &.colors-blue {
            &:before {
                background-color: $light-blue;
            }
        }
        &.colors-red {
            &:before {
                background-color: $light-red;
            }
        }
        &.colors-yellow {
            &:before {
                background-color: $light-yellow;
            }
        }
        &.colors-pink {
            &:before {
                background-color: $light-pink;
            }
        }
        &.colors-purple {
            &:before {
                background-color: $light-purple;
            }
        }

        &.colors-mobiliteit,
        &.colors-yellow {
            &:before {
                background-color: $theme-mobiliteit;
            }
        }
        &.colors-energy,
        &.colors-green {
            &:before {
                background-color: $theme-energy;
            }
        }
        &.colors-circulair {
            &:before {
                background-color: $theme-circulair;
            }
        }
        &.colors-nieuwetechnologie {
            &:before {
                background-color: $theme-nieuwetechnologie;
            }
        }
        &.colors-gezondheid {
            &:before {
                background-color: $theme-gezondheid;
            }
        }
        &.colors-onderwijs {
            &:before {
                background-color: $theme-onderwijs;
            }
        }
        &.colors-openbareruimte {
            &:before {
                background-color: $theme-openbareruimte;
            }
        }
    }
}
