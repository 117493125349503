@import "../../styles/shared/breakpoints";
@import "../../styles/shared/colors";
@import "../../styles/shared/typography";
@import "../../styles/shared/variables";

h1 {
  font-size: 1.5rem;
}

h2,
h3 {
  font-size: 1.25rem;
}

h4 {
  font-size: 1rem;
}

a {
  color: $primary-blue;
}

.het-doel {
  .imagebox {
    margin-top: 3rem;
  }
}

.fullpage {
  .index-item {
    display: flex;
    flex-direction: column;

    .item-link {
      padding: 0.75rem 0;
      padding-left: 1rem;
      font-weight: 300;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &:after {
        content: "";
        background: transparent url("/assets/icons/arrow_right_blue.svg")
          no-repeat;
        background-size: contain;
        height: 0.6rem;
        padding-right: 1.5rem;
        display: block;
      }
    }

    a {
      padding-bottom: 1.5rem;
      color: $primary-blue;
      text-decoration: none;
    }

    .active {
      background-color: $theme-aanbesteding;
      border-radius: 8px;
      font-weight: 600 !important;
      position: relative;

      &:after {
        background: transparent url("/assets/icons/arrow_down_blue.svg")
          no-repeat !important;
        background-size: contain;
        height: 0.5rem;
        padding-right: 1.5rem;
        display: block;
      }

      .title-list {
        font-weight: 600;
      }
    }
  }
}

.loket_container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 0rem;
  padding-right: 0rem;
  display: flex;
  flex-wrap: wrap;

  .col1 {
    .stickyMenu {
      @media screen and (min-width: 768px) {
        position: sticky;
        top: 120px;
        padding-bottom: 2rem;

        &.stickyMenuHigher {
          top: 20px;
        }
      }
    }

    width: 100%;
    margin-bottom: 3.75rem;

    @include size-tablet {
      width: 30%;
      margin-bottom: 0;
    }

    @include size-desktop {
      display: block;
      width: 25%;
      padding-right: 2rem;
    }

    @include size-large {
      display: block;
      width: 25%;
      padding-right: 3rem;
    }

    @include size-extra-large {
      padding-right: 5rem;
    }
  }

  .col2 {
    width: 100%;
    margin: auto;
    padding-bottom: 4rem;

    @include size-mobile {
      width: 100%;
    }

    @include size-tablet {
      width: 60%;
    }

    @include size-desktop {
      width: 50%;
      margin-left: 0;
    }

    @include size-large {
      display: block;
      width: 50%;
      margin-left: 0;
    }

    /* specific adjustment for display financebox component in loket page */
    .financebox {
      padding: 1rem;
      padding-left: 1.5rem;
    }

    .financebox__text {
      font-size: 1rem;
    }

    .financebox__countdown {
      height: 6.4rem;
      width: 5rem;

      span {
        font-size: 1.125rem;
        margin-top: 2.5rem;
      }

      &:before {
        background-size: 34px 34px;
        background-position: center 22.5%;
      }
    }
  }
}
