@import '../../styles/variables.scss';

.testimonials__wrapper.padded {
  padding-bottom: 2rem;
}

.testimonials__wrapper:not(.padded) > .testimonial {
  padding: 2.5rem 2.5rem 1.5rem 2.5rem;
}

.testimonial {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2.5rem 4.375rem;
}

.testimonial__quote {
  text-align: center;
  padding-bottom: 1.25rem;

  img:first-of-type {
    padding-right: .8rem;
    padding-bottom: .2rem;
  }
}

.testimonial__source, .testimonial__logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: .6rem;

  @include size-tablet {
    flex-direction: row;
  }

  .img__block {
    flex-shrink: 0;
    position: relative;
    width: 50px;
    height: 50px;

    .img__overlay {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      z-index: 400;
      border-radius: 50%;
      background: linear-gradient(136.49deg, rgba(228, 123, 161, 0) -19.07%, rgba(228, 123, 161, 0.2) 124.78%)
    }

    img {
      z-index: 300;
      height: 100%;
      width: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
  }

  .text {
    flex-shrink: 2;
    text-align: center;

    @include size-tablet {
      text-align: left;
    }

    p {
      opacity: .5;
      max-width: 20rem;
    }
  }
}

.testimonial__source {
  padding-bottom: .675rem;
}

.testimonial__logo {
  padding-bottom: 1.52rem;

  .img__block {
    height: 60px;
    width: auto;

    img {
      border-radius: 0px;
      width: auto;
    }
  }
}