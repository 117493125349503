@import "../../styles/variables.scss";

.breadcrumbs__container {
  display: flex;

  font-weight: 300;
  font-size: 0.75rem;
  margin-bottom: 1.8rem;

  ol {
    display: flex;
    list-style: none;
    align-items: center;

    li {
      padding-right: 0.5rem;
      display: flex;
      align-items: center;
      text-align: center;

      a {
        color: $primary-blue;
        text-decoration: underline;
      }

      &:after {
        content: ">";
        padding-left: 0.5rem;
      }
    }

    li:last-child {
      &:after {
        content: "";
      }
    }
  }
}
