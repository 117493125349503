@import "../../styles/variables.scss";

.logobar {
  &.horizontal {
    display: flex;
    justify-content: center;
    align-items: stretch;
    flex-wrap: wrap;
    gap: 1rem;
    padding: 2rem 0;

    .logo-item {
      min-height: auto;
      height: 5.5rem;
      margin: 0;

      .logo-image {
        width: 8.6rem;
      }
    }
  }

  a {
    text-decoration: none;
  }

  .logo-item {
    background-color: $grey-light;
    border-radius: $default-border-radius-small;
    display: flex;

    @media (min-width: #{$mobile-width}) {
      min-height: 7.5rem;
    }
    text-decoration: none;
    margin-bottom: 1.5rem;

    .logo-image {
      width: 30%;
      background-color: #fff;
      border: 2px solid $grey-light;
      border-radius: $default-border-radius-extra-small;
      display: flex;

      img {
        max-width: 80%;
        max-height: 5rem;
        margin: auto;
      }
    }
    .logo-text {
      width: 70%;
      padding: 1.625rem;
      padding-top: 1.25rem;
      h3 {
        line-height: 1.5rem;
        font-size: 1rem;
        font-weight: 600;
        margin-bottom: 0;
        position: relative;
        &:after {
          content: "";
          display: block;
          position: absolute;
          background-position: center;
          top: 0;
          right: 0;
          height: 20px;
          width: 20px;
          background-image: url("/assets/icons/arrow_right_blue.svg");
          background-repeat: no-repeat;
          background-size: 0.6rem;
        }
      }
      p {
        margin-bottom: 0;
      }
    }
  }
}
