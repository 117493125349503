@import "../../styles/variables.scss";

.deadlinebox {
  background-color: $default-panel-background-color;
  padding: 0.5rem;
  padding-left: 1rem;
  border-radius: $default-panel-radius-small;
  display: block;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  font-size: 0.8rem;
  flex-basis: 0;
  position: relative;
  margin: 3rem 0;
  .deadlinebox__date {
    margin-bottom: 0.1rem;
    font-weight: 600;
  }
  .deadlinebox__text {
    margin-right: 0.5rem;
  }
}

.deadlinebox__content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0;
  position: relative;
}

.deadlinebox__text {
  background-color: $default-panel-background-color;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 0.875rem;

  h4,
  p {
    font-size: 0.9rem;
    display: block;
    font-weight: 400;
  }
  span {
    display: block;
    font-weight: 400;
  }
}

.deadlinebox__countdown {
  background-color: #fff;
  border-radius: $default-border-radius-extra-small;
  padding: 0 1.2rem;
  position: relative;
  height: 4.5rem;
  line-height: 4.5rem;
  width: 3.2rem;
  align-self: center;
  span {
    display: block;
    font-weight: 300;
    font-size: 0.8rem;
    position: absolute;
    width: 100%;
    left: 0;
    text-align: center;
    top: -1.4rem;
    &:nth-child(2) {
      font-size: 1.5rem;
      font-weight: 600;
      position: absolute;
      top: 0rem;
      text-align: center;
    }
    &:last-child {
      top: 1.4rem;
    }
  }
  &:after {
    content: "";
    display: block;
    height: 0.125rem;
    width: 3.2rem;
    background-color: $default-panel-background-color;
    position: absolute;
    top: calc(50% - 0.125rem);
    left: 0;
  }
}
.deadlinebox__text_link {
  color: $default-text-color;
}
