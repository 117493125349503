@import "../../styles/variables.scss";

.beta_melding {
  padding: 2rem;
  background-color: $grey-light;
  border-radius: $default-border-radius-small;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 2.5rem;
  text-decoration: none;
  color: $primary-blue;

  @include size-tablet {
    flex-direction: row;

    img {
      width: unset;
      margin: unset;
      align-self: flex-end;
    }

    &.with_image {
      flex-direction: row-reverse;
    }
  }

  @media (max-height: 500px) {
    img {
      max-height: 15rem;
    }
  }

  .beta_melding__image {
    margin: auto;
    width: 50%;
    max-width: 13rem;
    max-height: 18rem;

    @include size-mobile {
      width: 100%;
      min-width: 11rem;
      margin-left: 1rem;
      max-width: unset;
    }
  }

  .beta_content {
    h3 {
      font-size: 1.25rem;
      font-weight: 500;
      margin-bottom: 1rem;
    }

    a {
      color: $primary-blue;
    }
  }

  .button__cta {
    @include button-type;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0px;
    border-radius: $default-border-radius-extra-small;
    padding: 1rem 2rem;
    margin-top: 2rem;
    color: white;
    text-decoration: none;
    background-color: $primary-blue;
    width: 100%;
    min-height: 59px;
  }

  &.card {
    @include base-card;
    padding: 1rem;
    background-color: #fff;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    .beta_content {
      padding: 0;
    }

    .beta_melding__image {
      margin: 0 auto;
    }

    &.horizontal {
      padding: 1.5rem 2rem;
    }
  }
}
