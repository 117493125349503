@import "../../styles/variables.scss";

@mixin projectlabel {
  color: $grey-dark;
  font-size: .875rem;
  font-weight: 500;
  padding: .5rem .9375rem;
  border-radius: 2.5rem;
  margin: 0.2rem 0.1rem;
}

.projectlabels__container {
  display: flex;
  flex-wrap: wrap;
  margin-top: -.5rem;
  margin-bottom: 2rem;
}
.projectlabels__theme {
  @include projectlabel;
  background-color: $projectlabel-yellow;
  ;
}
.projectlabels__category {
  @include projectlabel;
  background-color: $projectlabel-green;
  ;
}

