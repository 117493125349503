@import "../../styles/variables.scss";

.credits__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem 0;
  text-align: center;

  .credits-title {
    font-weight: 600;
    margin-bottom: 1.5rem;
  }

  p:nth-child(3) {
    margin-top: 1rem;

    @include size-tablet {
      margin-top: unset;
    }
  }

  a {
    text-decoration: underline;
    color: $primary-blue;
  }

  img {
    width: 6.9rem;
  }
}

.subtitle-section {
  text-align: center;
  margin-bottom: 1.75rem;

  h2 {
    margin-bottom: 0;
    line-height: 2.25rem;
  }

  p {
    line-height: 1.75rem;
  }
}

.home {
  .home__content_block {
    padding: 2rem 0;
  }

  .bg-light {
    background-color: rgba($primary-blue, 0.05);
    padding: 4rem 0;
  }

  .container__small {
    max-width: 38.75rem;
  }

  .testimonials__wrapper {
    max-width: 38.75rem;
    margin: auto;
    padding-bottom: 0;
  }
}
