@import "../../styles/variables.scss";

.divider {
  display: block;
  width: 100%;
  border-bottom: 1px solid $default-panel-background-color;
  margin: auto;
  margin-top: 4rem;
  margin-bottom: 4rem;
}
