@import "../../styles/variables.scss";

.participate__header {
  padding: 2rem 0;
  display: flex;
  flex-direction: column;

  .participate__header__intro {
    h1 {
      font-weight: 600;
      font-size: 1.75rem;
      margin-bottom: 1.5rem;
    }

    p {
      strong {
        font-weight: 500;
      }

      &:last-child {
        margin-bottom: 2rem;
      }
    }
  }

  ul {
    display: flex;
    flex-direction: column;
    list-style-type: none;

    li {
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      min-width: 50%;
      flex-grow: 1;
      height: 3rem;
      padding-left: 3.8rem;
      margin: 1rem 0;
      font-size: 1.25rem;
      font-weight: 500;
      background-repeat: no-repeat;
      background-position-y: center;

      @include size-tablet {
        margin: 0.7rem 0;
      }
    }
  }
}
