@import "../../styles/variables.scss";

.blogs__page_content {
  margin-bottom: 4rem;
}

.cards__grid {
  max-width: 100%;
  margin: 0 auto;
  display: grid;
  gap: 1.25rem;

  @include size-tablet {
    grid-template-columns: repeat(2, 1fr);
  }

  @include size-large {
    grid-template-columns: repeat(3, 1fr);
  }
}

.blogs__header {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  @include size-tablet {
    justify-content: space-between;
    align-items: flex-end;
    flex-direction: row;
  }
}

.blogs__theme_selector {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  margin-bottom: 1.5rem;
}

.blogs__no_results {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4rem;

  * {
    margin: 0;
  }
}

.blogs__pagination {
  padding-top: 3rem;
}
