@import "../../styles/variables.scss";

.swiper-button-prev,
.swiper-button-next {
  background-color: $primary-blue;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 0.25rem;

  &::after {
    content: "";
  }

  img {
    height: 1rem;
  }
}

.swiper-button-prev {
  img {
    transform: rotate(180deg);
  }
}

.swiper-pagination-bullet {
  width: 1.563rem;
  height: 0;
  border-radius: 0.25rem;
  background: $primary-blue;
  border: 0.125rem solid $primary-blue;
  margin: 0 0.25rem !important;

  &.swiper-pagination-bullet-active {
    margin: -0.063rem 0.25rem !important;
    background-color: $primary-pink;
    border: 0.188rem solid $primary-pink;
  }
}
