@import "./src/styles/shared/colors.scss";


@keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }

#sendloader {
    animation: rotation 2s infinite linear;
    height: 82px;
    width: 82px;
    float: right;
}

.timeline{
    h3{ 
        margin: 0;
    }
}

.timeline__item_content{

    h3 :not(first-child){ 
        margin-top: 2rem;
    }
}

.timeline__item_button {
    align-items: center;
}


.timeline-review{
    
    .accordion__heading{
        border-top: solid 1px $grey-light;
    }
    
    .timeline__item_content{
        margin-bottom: 3rem;
    }
}

.timeline.timeline-signin .timeline__item_bullet{
    border-color:  $primary-blue;
    
    span{
        color: $primary-blue;
    }
}

.edit{
    margin-left: auto;
    background: none;
    border:none;
    color: $primary-blue;
    text-decoration: underline;
    cursor: pointer;
    vertical-align: top;
    display: flex;
    font-size: .8rem;
    img {
        height: 1rem;
        padding-right: .5rem;
    }
}

.button__apply.button-outline{
    background:none;
    color:$primary-blue;
    border: solid 1px $primary-blue;
    
    &:hover{
        background: $primary-blue;
        color:white
    }
}

.download__bar{
    background: none;
    
    .text__part > p{
        color:$primary-blue;
        font-weight: bold;
        margin: 0;
    }
}
