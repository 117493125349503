@import '../../styles/variables.scss';

.pagination__container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: .5rem;

  .prev, .next, .page {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: .25rem;
    background-color: $primary-blue;
    border: 1px solid $primary-blue;
    cursor: pointer;
    outline: inherit;

    &:disabled {
      opacity: .4;
    }
  }

  .page {
    background-color: #fff;
    color: $primary-blue;
    opacity: .5;

    &.active, &:hover {
      opacity: 1;
    }

    &.active {
      font-weight: bold;
    }
  }

  .prev svg {
    transform: scaleX(-1);
  }
}