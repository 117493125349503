@import "../../styles/variables.scss";

.project__details__container {
  border-top: 1px solid $default-panel-background-color;
  padding-top: 1rem;

  .project__details__list {
    padding-top: 1rem;
    border-bottom: 1px solid $default-panel-background-color;
    padding-bottom: 1rem;
    margin-bottom: 1rem;

    &:last-of-type {
      border-bottom: none;
    }

    .project__details__item {
      padding-bottom: 1.5rem;
    }
  }

  // first column left + more columns right
  @include size-mobile {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: auto;

    .project__details__list {
      padding-top: 1rem;

      &:first-of-type {
        grid-row: span 99;
        border-bottom: none;

        &:not(:last-of-type) {
          border-right: 1px solid $default-panel-background-color;
          padding-right: 2rem;
          margin-right: 1rem;
        }
      }

      &:not(:first-of-type) {
        padding-left: 2rem;
        border-bottom: 1px solid $default-panel-background-color;
        padding-bottom: 1rem;
        margin-bottom: 1rem;
      }

      &:not(:first-of-type):last-of-type {
        border-bottom: none;
      }
    }
  }
}
