@import "../../styles/variables.scss";

.qa-title{
    display: block;
    width: 100%;
}

.qa-header{
    width: 100%;
    text-align: left;
    margin-bottom: 5rem;
    
    h4{
        width: 100%;
    }
}

.qa-header-item{
    display: flex;
    flex-direction: row;
    width: 100%;
    text-align: left;
    padding: 0.5rem 0;
    
    @media (max-width: #{$mobile-width}) {
        justify-content: space-between;
    }

}
.qa-header-item-title{
    
    @media (min-width: #{$mobile-width}) {
        width: 24rem;
    }
}   

.qa-question-box{
    display: flex;  
    justify-content: center;
    flex-wrap: wrap;
}

.qa-question{
    
    width: 100%;

    @media (min-width: #{$mobile-width}) {
        width: 50%;
    }

}

.qa-answer{
    width: 100%;
    margin-top: 1rem;

    @media (min-width: #{$mobile-width}) {
        width: 50%;
    }

    h4 { width: 100%; }
    
    .qa-textarea{
        width: 100%;
        border: none;
        margin: .5rem 0;

        padding: 1.5rem;
        background-color: $grey-light;
        
    }

    .qa-select{
        width: 100%;
        border: solid 1px $grey-light;
        padding: .5rem;
        border-radius: .5rem;
        margin: .5rem 0;  
        -webkit-appearance:none;      
    }

    .qa-submit{
        border: none;
        margin: 1rem 0;
        padding: .75rem 1rem;
        background-color:$primary;
        color: white;

        @media (max-width: #{$mobile-width}) {
            width: 100%;
        }
    }
    
}


.qa-select{
    display: block;
    width: 100%;
    padding: 1.5rem;
    margin: .5rem 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    color: $primary-blue;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: right 1.5rem center;
    background-size: 14px 8px;
    border: 1px solid $primary-blue;
    border-radius: $default-border-radius-small;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    transition: border .2s ease-in-out;
    &:after {
        display: block;
        clear: both;
        content: "";
    }
    &:hover {
        border: 1px solid rgba(47, 25, 107, 0.32);
    }      
}

.select-disabled {
    background-image: none;
    border: 1px solid rgba(47, 25, 107, 0.32);
}
.select-enabled {
    background-image: url("/assets/icons/arrow_down_blue.svg");
}

.question-specs-wrapper em {
    color: #eeebeb;
}

.question-specs-wrapperdel {
    background-color: #eee;
    color: #bbb;
}


.about-content  .question__link {
    text-decoration: none;
    color: #999;

    span {
        text-decoration: underline;
        display: block;
        color: $primary
    }
}

.questiondeleted {
    padding-bottom: 1rem;
    color: rgb(255, 88, 88);
}