$default-card-radius: 0.5rem;
$default-card-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);

$default-border-radius-extra-small: 0.25rem;
$default-border-radius-small: 0.5rem;
$default-border-radius-large: 1.5rem;
$default-border-radius-extra-large: 2.5rem;

$default-margin: 1rem;
$default-panel-padding: 1rem;
$default-panel-radius-small: $default-border-radius-small;
$default-panel-radius-large: $default-border-radius-large;

@mixin content-width {
  @include size-mobile {
    width: 100%;
  }
  @include size-tablet {
    width: 100%;
    margin: auto;
  }
  @include size-desktop {
    width: $desktop-width;
    margin: auto;
  }
  @include size-large {
    width: $large-width;
    margin: auto;
  }
}

@mixin base-card {
  border-radius: $default-card-radius;
  box-shadow: $default-card-shadow;
}

@mixin base-panel {
  border-color: transparent;
  margin-bottom: $default-margin;
  background-color: $default-panel-background-color;
  padding-left: $default-panel-padding;
  padding-right: $default-panel-padding;
  border-radius: $default-panel-radius-small;
}

/* bullet in timeline */
@mixin bullet($bg-color, $border, $color) {
  background-color: $bg-color;
  border: $border;
  color: $color;
  span {
    color: $color;
  }
}
