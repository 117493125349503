@import "../../styles/variables.scss";

.blog {
  .blog_cover {
    padding: 0;
    min-height: 11rem;
    max-height: 30rem;
    object-fit: cover;
    overflow: hidden;

    img {
      width: 100%;
    }
  }

  .blog_cover_fallback {
    width: 100%;
    height: 2rem;

    @include size-tablet {
      height: 10rem;
    }
  }

  .blog_text,
  .blog_header {
    position: relative;
    max-width: 42.5rem;
    background-color: #fff;
    padding: 1.5rem 1rem;

    @include size-tablet {
      padding: 2rem;
    }

    .imagebox > img {
      border-radius: 0.5rem;
    }
  }

  .blog_header {
    box-shadow: 0px 0px 56px 0px #11111129;
    padding-bottom: 0rem;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    margin-top: -2rem;

    @include size-tablet {
      margin-top: -8rem;
    }
  }

  .blog_meta {
    display: flex;
    flex-direction: column-reverse;
    margin-bottom: 1rem;
    opacity: 0.5;

    @include size-tablet {
      flex-direction: row;
      justify-content: space-between;
    }
  }
}
