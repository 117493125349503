@import "../../styles/variables.scss";

@mixin base-grid {
  width: 100%;
  margin: auto;
  padding-left: 1rem;
  padding-right: 1rem;
  position: relative;

  &.cols {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    @include size-tablet {
      flex-direction: row;
    }
  }
}

.gridrow__full {
  @include base-grid;
  padding-left: 0rem;
  padding-right: 0rem;

  @include size-tablet {
    // on a 12 col grid 83.3 == 10 col, so a 1 col margin on each side
    padding-left: 0rem;
    padding-right: 0rem;

    &.color_block {
      min-height: 22rem;

      &:before {
        content: "";
        display: block;
        width: 90%;
        margin-left: 5%;
        margin-right: 5%;
        height: 20rem;
        border-radius: $default-panel-radius-large $default-panel-radius-large 0
          0;
        position: absolute;
        @include size-large {
          width: 90%;
          margin-left: 5%;
          margin-right: 5%;
        }
        @include size-extra-large {
          width: 80%;
          margin-left: 10%;
          margin-right: 10%;
        }
      }
    }

    &.green::before {
      background-color: $theme-energy;
    }

    &.purple::before {
      background-color: $primary-purple;
    }

    &.pink::before {
      background-color: $primary-pink;
    }
  }
  @include size-desktop {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    // width: 42%;
  }
}

.gridrow__large {
  @include base-grid;
  min-width: auto;
  @include size-tablet {
    // on a 12 col grid 83.3 == 10 col, so a 1 col margin on each side
    width: 83.3%;
    padding-left: 0rem;
    padding-right: 0rem;
    min-width: auto;
  }
  @include size-large {
    // on a 12 col grid 66.6% == 8 col, so a 2 col margin on each side
    width: 66.6%;
    min-width: 960px;
  }
}

.gridrow__small {
  @include base-grid;
  padding: 0px;
  @include size-tablet {
    // on a 12 col grid 83.3 == 10 col, so a 1 col margin on each side
    width: 80%;
    // min-width: 730px;
  }
  @include size-large {
    width: 66%;
    min-width: 824px;
    max-width: 1000px;
  }
  .block {
    padding: 0rem 0 2.5rem 2rem;
    @include size-tablet {
      padding-left: 0;
      padding-top: 4rem;
    }
  }
  &.dashboard__projects {
    min-width: unset;
    @include size-tablet {
      width: 70%;
    }
    @include size-large {
      width: 66%;
    }
  }
}
