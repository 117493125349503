@import '../../styles/variables.scss';

.select_wrapper {
  width: 100%;
  min-width: 15rem;

  .select_container {
    position: relative;
    
    button {
      cursor: pointer;
      width: 100%;
      padding: .8rem;
      color: $primary-blue;
      background-color: #fff;
      border: 1px solid $primary-blue;
      border-radius: .5rem;
      display: flex;
      flex-wrap: nowrap;
      white-space: nowrap;
      justify-content: space-between;
      align-items: center;
      gap: 4rem;

      &.placeholder {
        color: $primary-blue;
      }

      .select_icons {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 1rem;

        .remove {
          opacity: .5;

          &:hover, &:focus, &:active {
            opacity: 1;
          }
        }
      }
    }

    ul.options {
      background-color: #fff;
      border: 1px solid $primary-blue;
      border-radius: .5rem;
      display: none;

      &.show {
        z-index: 999;
        display: block;
        position: absolute;
        top: 3rem;
        right: 0;
        left: 0;
        min-width: auto;
        overflow: auto;
      }

      .options_inner {
        max-height: 50vh;
        overflow-y: scroll;
      }

      li {
        list-style-type: none;
        cursor: pointer;
        padding: .8rem;
        border-bottom: 1px solid $grey-light;

        &:hover, &:focus, &:active {
          background-color: $grey-light;
        }

        &[aria-selected="true"] {
          background-color: $grey-input;
        }
      }

      li:last-of-type {
        border-bottom: none;
      }
    }
  }
}