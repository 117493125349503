@import "./typography.scss";
@import "./colors.scss";
@import "./breakpoints.scss";
@import "./formelements.scss";

@import "./variables.scss";

// from siteheader component (deleted) to shared (class is used a lot)
.form-error {
  left: 0;
  font-size: 0.875rem;
  color: #d52600;
}

.form-group .react-tel-input .inpurClass {
  width: 100%;
  padding-left: 48px;
  height: 100%;
  background-color: #f2f2f2;
}

.phoneClass {
  width: 100%;
  background-color: #f2f2f2;
  margin-bottom: 1rem;
  border: none;
  height: 3rem;
  outline: none;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */
}

/**
 * Show the overflow in IE.
 * 1. Show the overflow in Edge.
 */

button,
input {
  /* 1 */
  overflow: visible;
}

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */

button,
select {
  /* 1 */
  text-transform: none;
}

/**
 * Correct the inability to style clickable types in iOS and Safari.
 */

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

/**
* Base styles
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
caption {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  // font-size: 1rem;
  vertical-align: baseline;
  background: transparent;

  @media (max-width: #{$mobile-width}) {
    // font-size: 80%;
    // font-size: 2.5vw;
    // font-size: 0.8rem;
  }

  @include size-mobile {
    // font-size: 90%;
    // font-size: 2vw;
    // font-size: 0.9rem;
  }

  @include size-tablet {
    // font-size: 100%;
  }

  @include size-desktop {
    // font-size: 1rem;
  }
}

body {
  margin: 0 !important;
  padding: 0 !important;
}

/* responsive font size general */
html {
  // font-size: 1rem;

  // from 0 to size-mobile = 576px
  @media (max-width: 576px) {
    font-size: 83%;
  }
  // from size-mobile = 576px
  @media (min-width: 576px) {
    font-size: 85%;
  }
  // from size-tablet = 768px
  @media (min-width: 768px) {
    font-size: 90%;
  }
  // from size-desktop = 992px
  @media (min-width: 992px) {
    font-size: 100%;
  }
}

*,
*:after,
*:before {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

html,
body {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: normal;
  line-height: 1.5;
  color: $default-text-color;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

h1 {
  font-weight: 600;
  font-size: 4.5em;

  span {
    display: block;
    font-weight: 300;
    line-height: 0.9;
  }
}

h2 {
  font-weight: 600;
  font-size: 1.75em;
  margin-bottom: 1rem;
}

h3 {
  font-weight: 600;
  font-size: 1.5em;
  line-height: 1.25;
  margin-bottom: 2rem;
}

p {
  font-weight: 300;
}

/* class for the smallest column width */
.small-width {
  width: 100%;
  margin-left: auto;
  margin-right: auto;

  @include size-mobile {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
  }

  @include size-tablet {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
  }

  @include size-large {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
  }
}

/* class for the largest column width */
.large-width {
  width: 100%;
  margin-left: auto;
  margin-right: auto;

  @include size-tablet {
    width: 100%;
    // min-width: 824px;
    margin-left: auto;
    margin-right: auto;
  }

  @include size-desktop {
    width: 66%;
    min-width: 824px;
    margin-left: auto;
    margin-right: auto;
  }

  @include size-large {
    width: 66%;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
  }
}

/* class with only backgroundcolor  */

.bg_color_lila {
  background-color: $theme-aanbesteding;
}

.bg_color_pink {
  background-color: $theme-openbareruimte;
}

.bg_color_purple {
  background-color: $theme-gezondheid;
}

.bg_color_babyblue {
  background-color: $theme-aanbesteden-baby;
}

.bg_color_turquoise {
  background-color: $theme-circulair;
}

.bg_color_green {
  background-color: $theme-energy;
}

.bg_color_yellow {
  background-color: $theme-mobiliteit;
}

/* 

Dit is generiek voor alle plekken waar lijsten moeten worden gestyled 
Zou wellicht beter in een component kunnen.

*/

.list__Checkmarks,
.list__CheckmarksGreen,
.list__LocationMarkersGreen,
.list__LocationMarkersYellow {
  ul {
    li {
      background-repeat: no-repeat;
      background-position: left center;
      list-style: none;
      font-size: 1rem;
      font-weight: 300;
      padding: 1.3rem 0;
      padding-left: 4rem;

      a {
        color: $primary-blue;
      }

      &:last-child {
        margin-bottom: 0.5rem;
      }

      @include size-tablet {
        padding: 1rem 0;
        padding-left: 4rem;
      }

      // &:last-child {
      //   margin-bottom: 3rem;
      // }
    }
  }
}

.list__Checkmarks {
  ul li {
    background-image: url("/assets/icons/project_list_vink.svg");
  }
}

.list__CheckmarksGreen {
  ul li {
    background-image: url("/assets/icons/project_list_vinkgreen.svg");
  }
}

.list__LocationMarkersGreen {
  ul li {
    background-image: url("/assets/icons/project_list_green.svg");
  }
}

/* class .list__null is added after .content__basic in order to pass custom styling. This doesn't work properly therefore the !important */
.list__null {
  ul li {
    list-style: disc !important;
    margin-left: 1rem !important;
    padding: 0.5rem 0 !important;
    padding-left: 0.5rem !important;
  }
}

.content__basic {
  margin-bottom: 3rem;

  h3,
  h1 {
    margin-bottom: 1rem;
  }

  h1 {
    font-size: 1.5rem;
  }

  h2 {
    font-size: 1.25rem;
  }

  p {
    margin-bottom: 1.5rem;

    strong {
      font-weight: 700;
    }

    a {
      text-decoration: underline;
    }
  }

  ol {
    padding-left: 1rem;
    font-weight: 300;
  }

  ul {
    margin-bottom: 1.4rem;

    li {
      background-repeat: no-repeat;
      background-position: left center;
      background-size: 2.5rem;
      list-style: none;
      font-size: 1rem;
      font-weight: 300;
      margin: 1rem 0;
      padding: 0.3rem 0 0.3rem 4rem;
      min-height: 2.5rem;

      a {
        color: $primary-blue;
      }

      &:last-child {
        margin-bottom: 0.5rem;
      }

      @include size-tablet {
        margin: 0.8rem 0;
        padding: 0.3rem 0 0.3rem 4rem;
        min-height: 2.5rem;
      }
    }
  }
}

.external__link {
  text-decoration: underline !important;

  &:after {
    content: url("/assets/icons/link-arrow.svg");
    text-decoration: underline;
    padding-left: 0.2rem;
    display: inline;
  }

  &.upload-bar {
    text-decoration: none !important;
  }
}

.timeline__item_content {
  ul {
    padding-left: 1rem;
    margin-bottom: 1rem;

    li {
      margin-bottom: 0.3rem;
      font-weight: 300;
    }
  }
}

/* text-only-button for popups "Sluit venster" */
.text-only-button {
  display: block;
  margin-left: auto;
  margin-right: 0px;
  background-color: transparent;
  border: none;
  color: $primary-blue;
  text-decoration: underline;
}

/* general buttons for signin section */
@mixin siginin-buttons($bg-color, $color, $border) {
  background-color: $bg-color;
  color: $color;
  border: $border;
}
.btn-signin-purple,
.btn-signin-transparent,
.btn-signin-white,
.btn-signin-grey {
  border: none;
  border-radius: $default-border-radius-extra-small;
  line-height: 1.5rem;
  padding: 0.5rem 1rem;

  a {
    text-decoration: none !important;
    color: inherit;
  }

  &.locked {
    opacity: 0.32;
  }
}
.btn-signin-purple {
  @include siginin-buttons($primary-blue, #fff, 1px solid $primary-blue);
}
.btn-signin-white {
  @include siginin-buttons(#fff, $primary-blue, 1px solid #fff);
}
.btn-signin-grey {
  @include siginin-buttons(
    $signin-mid-grey,
    $primary-blue,
    1px solid $signin-mid-grey
  );
}
.btn-signin-transparent {
  @include siginin-buttons(transparent, $primary-blue, 1px solid $primary-blue);
}
