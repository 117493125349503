$mobile-width: 36rem; // 576px at font size 16px;
$tablet-width: 48rem; // 768px at font size 16px;
$desktop-width: 62rem; // 992px at font size 16px;
$large-width: 75rem; // 1200px at font size 16px;
$extra-large-width: 87.5rem; // 1400px at font size 16px;
$huge-width: 100rem; // 1600px at font size 16px;

@mixin size-mobile {
  @media (min-width: #{$mobile-width}) {
    @content;
  }
}

@mixin size-tablet {
  @media (min-width: #{$tablet-width}) {
    @content;
  }
}

@mixin size-up-to-desktop {
  @media (width < $desktop-width) {
    @content;
  }
}

@mixin size-desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}

@mixin size-large {
  @media (min-width: #{$large-width}) {
    @content;
  }
}

@mixin size-extra-large {
  @media (min-width: #{$extra-large-width}) {
    @content;
  }
}

@mixin size-huge {
  @media (min-width: #{$huge-width}) {
    @content;
  }
}

.up-to-desktop {
  display: none;
  @media (width < $desktop-width) {
    display: inherit;
  }
}
