@import "../../styles/variables.scss";

.qna__question__form {
  background-color: $theme-aanbesteding;
  padding: 2.5rem;
  border-radius: $default-border-radius-extra-small;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  .form-error {
    margin: 0.3rem 0 0.5rem 0;
  }

  .field {
    input,
    textarea {
      width: 100%;
      border: 1px solid $white;
      border-radius: 0.125rem;
      padding: 0.8rem;
      color: $primary-blue;
      font-weight: 200;

      &:focus,
      &:active {
        outline: none;
        border: 1px solid $primary-lightblue;
      }
    }

    textarea {
      display: block;
      height: 6.25rem;
    }

    ::placeholder {
      color: $primary-blue;
    }

    .checkbox__wrapper {
      margin: 0;

      a {
        margin-left: 0.3rem;
      }
    }
  }

  .submit__form {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $primary-blue;
    color: #ffffff;
    text-decoration: none;
    border-radius: $default-border-radius-extra-small;
    font-size: 1.125rem;
    font-weight: 500;
    padding: 1rem;
    border: none;

    img {
      margin: 0 1rem;
      width: 1.375rem;
    }
  }

  .subscript {
    opacity: 0.8;
    font-size: 0.9rem;
  }
}
