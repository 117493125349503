@import "../../styles/variables.scss";

.aanbesteding-cards {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  margin: 4rem auto;

  @include size-tablet {
    flex-direction: row;
  }

  .inkopen__aanbesteden {
    box-shadow: 0px 0px 80px 0px #bbb;
    width: 100%;
    border-radius: $default-border-radius-small;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 2rem;
    margin-bottom: 2rem;
    text-decoration: none;
    color: $primary-blue;
    min-height: 32rem;

    .flex-orientation {
      display: flex;
      flex-direction: column;

      h3 {
        font-size: 1.25rem;
        font-weight: 500;
        margin-bottom: 1.5rem;
      }

      p {
        margin-bottom: 1.5rem;
      }

      strong {
        font-weight: 300;
        text-decoration: underline;
      }

      a {
        font-weight: 300;
        margin-bottom: 1.5rem;
      }
    }

    img {
      max-height: 19rem;
      margin-top: 1rem;
      margin-left: auto;
      margin-right: auto;
      max-width: 100%;

      @include size-tablet {
        margin-top: unset;
        width: 100%;
      }

      @include size-desktop {
        max-height: 16rem;
      }
    }

    @include size-mobile {
      width: 100%;
      flex-direction: row;
      // margin-bottom: unset;
      min-height: unset;
    }

    @include size-tablet {
      width: 48.3%;
      margin-bottom: unset;
      flex-direction: column;
      min-height: 37rem;
    }
  }
}
