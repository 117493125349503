@import "../../styles/variables.scss";

.intro__title {
  margin-bottom: 2.4375rem;
  h3 {
    margin-bottom: 1rem;
    font-size: 1.5rem;
  }
  p {
    margin-bottom: 1rem;
  }
}

.search__kennisbank {
  display: flex;
  // margin-top: 2.4rem;
  margin-bottom: 3.5rem;

  label {
    flex-grow: 1;
  }

  input {
    height: 48px;
    width: 100%;
    flex-grow: 1;
    border-radius: 2px;
    background-color: $grey-light;
    border: none;
    color: $primary-blue;
    padding-left: 1rem;
    padding-right: 1rem;
    -webkit-appearance: none;
    line-height: normal;
    &:focus {
      outline: 0px;
      box-shadow: none;
    }
  }
  ::placeholder {
    color: $primary-blue;
    opacity: 1;
  }
  button {
    display: block;
    padding-left: 0.5rem;
    border: none;
    background-color: white;
  }
}

.subject__block__container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  column-gap: 24px;
  row-gap: 24px;
  margin-bottom: 4rem;

  .subject__item {
    border-radius: $default-border-radius-small;
    padding: 1.3rem 1.5rem 1.8rem 1rem;
    text-decoration: none;
    color: $primary-blue;
    min-height: 8rem;

    .subject__title {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      margin-bottom: 1.8rem;
      h4 {
        font-size: 1.25rem;
        font-weight: 500;
      }
      img {
        margin-top: 0.6rem;
      }
    }
    p,
    a {
      font-size: 0.9rem;
      font-weight: 300;
    }
  }
}
.item__title {
  margin-bottom: 1rem;

  h4 {
    font-size: 1.25rem;
    font-weight: 500;
    margin-bottom: 1rem;
  }
  p {
    margin-bottom: 1rem;
    a {
      display: inline;
    }
  }
  a {
    font-weight: 300;
    margin-bottom: 1rem;
    display: block;
  }
}
.download__bar {
  background-color: $primary-blue;
  border-radius: $default-border-radius-small;
  text-decoration: none !important;
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
  padding: 0.6rem 0.8rem;
  @include size-large {
    padding: 1rem 0.8rem;
  }
  .text__part {
    display: flex;
    align-items: center;
    flex-grow: 1;
    img {
      max-width: 1.8rem;
    }
    p {
      font-weight: 300;
      padding-left: 1.4rem;
      color: #fff;
      b {
        font-weight: 600;
      }
    }
  }
}

.ask__question__form {
  background-color: $theme-aanbesteding;
  display: flex;
  padding: 2.5rem;
  border-radius: $default-border-radius-extra-small;
  margin-bottom: 4rem;
  margin-top: 2rem;

  input,
  textarea {
    width: 100%;
    border: none;
    border-radius: 0.125rem;
    height: 3rem;
    padding-left: 0.8rem;
    margin-bottom: 0.5rem;
    color: $primary-blue;
    font-weight: 200;
  }
  .checkbox__container {
    .checkmark {
      top: 0.15rem;
    }
  }

  label {
    display: block;
    margin-bottom: 0.625rem;
    font-weight: 200;
    font-size: 0.9rem;
  }
  ::placeholder {
    color: $primary-blue;
    opacity: 1;
  }
  .long__text {
    height: 6.25rem;
    padding-top: 0.7rem;
  }
  .submit__form {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3rem;
    background-color: $primary-blue;
    color: #ffffff;
    text-decoration: none;
    border-radius: $default-border-radius-extra-small;
    font-size: 1.125rem;
    font-weight: 500;
    margin-bottom: 2.3rem;
    img {
      margin-left: 1rem;
      width: 1.375rem;
    }
  }
  .indenting-bottom {
    margin-bottom: 1.9rem;
  }
}

.item__question {
  margin-bottom: 1.5rem;
  h4 {
    font-size: 1.125rem;
    margin-bottom: 0.5625rem;
    font-weight: 600;
  }
}

.apply__button {
  width: 100%;
  border: none;
}

.search-results {
  margin-bottom: 3rem;

  h3 {
    margin-bottom: 0.6875rem;
  }
}
