.switch-link { 
  // TODO: HACK: Should use proper CSS.
	background: none !important;
	color: inherit !important;
	border: none !important;
	padding: 0;
	font: inherit !important;
	cursor: pointer !important;
	outline: inherit !important;
	width: unset !important;

  text-decoration: underline;
  padding-right: 1rem;
}
