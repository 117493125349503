$primary-blue: #2f196b;
$primary-lightblue: #78d8e5;
$primary-pink: #e47ba1;
$primary-purple: #d8bdee;
$primary-yellow: #fdd855;

$light-blue: #7b6ea0;
$light-red: #fde7e7;
$light-yellow: #fdefae;
$light-pink: #f8dce6;
$light-purple: #ece8fa;

$soft-purple: #645983;

$checkbox-icon: #38c45f;

// TODO: check usage & possibly regroup (color backlog)
$theme-mobiliteit: #fdd855;
$theme-energy: #6be695;
$theme-circulair: #71ebd5;
$theme-nieuwetechnologie: #78d8e5;
$theme-gezondheid: #7d95e8;
$theme-onderwijs: #d8bdee;
$theme-openbareruimte: #e47ba1;
$theme-aanbesteding: #e8d3f5;
$theme-aanbesteden-baby: #78d8e6;

$signin-box: #ebe8f8;
$signin-grey: #e0e0e0;
$signin-process: #ff007a;
$signin-mid-grey: #dddada;
$signin-light-grey: #f7f5f5;
$signin-error: #d52600;
$signin-valid: #278e43;
$signin-middle-grey: #858585;

$dahboard-background: #d2d2d2;

$projectlabel-yellow: #f9d537;
$projectlabel-green: #2dd265;

$white: #fff;

$grey-dark: #111;
$grey: #666;
$grey-light: #eeebeb;
$grey-form: #f1eeed;
$grey-input: #f2f2f2;

$primary: $primary-blue;
$primary-light: $theme-gezondheid;
$secondary: $light-purple;
$tertiary: $theme-mobiliteit;

$default-text-color: $primary-blue;
$default-button-text-color: $primary-blue;
$default-panel-background-color: $grey-light;
