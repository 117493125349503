@import "../../styles/variables.scss";

.signin-accordion {
  .signin-timeline-title {
    h1 {
      margin-bottom: 1rem;
      font-size: 1.75rem;
    }
    p {
      margin-bottom: 1.6rem;
    }
  }
}

.button__apply.button__red {
  background-color: #900 !important;
}

/* custom timeline styling */
.timeline {
  label {
    display: block;
    margin-bottom: 0.5rem;
  }
  &.timeline-signin {
    .timeline__item_bullet {
      @include bullet(white, 2px solid $signin-process, $signin-process);
      span {
        font-size: 1.125rem;
      }
    }
    .timeline__item {
      &:after {
        background-color: $signin-grey;
        width: 4px;
      }
      .timeline__item_button {
        .timeline__item_title {
          h3 {
            font-size: 1.25rem;
          }
        }
        &.bullet-checked {
          .timeline__item_bullet {
            @include bullet($checkbox-icon, 2px solid $checkbox-icon, #fff);
            span {
              display: none;
            }
            &:after {
              content: "";
              position: absolute;
              left: 0.9rem;
              top: 0.45rem;
              width: 0.75rem;
              height: 1.4rem;
              border: 4px solid #ffffff;
              border-radius: 0.125rem;
              border-width: 0 0.25rem 0.25rem 0;
              -webkit-transform: rotate(45deg);
              -ms-transform: rotate(45deg);
              transform: rotate(45deg);
            }
          }
        }
      }

      .timeline__item_content {
        margin-bottom: 1.875rem;
        margin-right: 4.75rem;

        .header-timeline-signin {
          margin-bottom: 1rem;
        }
        .info-text {
          margin-bottom: 1.75rem;
        }
      }
      &.open {
        .timeline__item_bullet {
          @include bullet($signin-process, 2px solid $signin-process, #fff);
        }
      }
    }
    .form-alert {
      background-color: black;
    }

    /* custom checkbox styling */

    .checkbox__wrapper {
      margin-bottom: 0.5rem;
      p {
        font-size: 1rem;
        margin-bottom: 0;
      }
      .checkbox__container {
        padding-left: 2.9375rem;

        input {
          height: 1.5rem;
          width: 1.5rem;
          top: 0;
          left: 0;
        }
        .checkmark {
          height: 1.5rem;
          width: 1.5rem;
          background-color: #fff;
          &:after {
            width: 0.6rem;
            height: 0.8rem;
            left: 0.4rem;
            top: 0.1rem;
          }
        }

        input:checked ~ .checkmark {
          background-color: $primary-blue;
        }

        /* On mouse-over, add a grey background color */
        &:hover input:not([disabled]) ~ .checkmark {
          background-color: $primary-blue;
        }
      }
    }
    .checkboxes,
    .dropdown,
    .radio-button,
    .date-picker,
    .text-input,
    .textarea-input {
      margin-bottom: 1.7rem;
    }
    .checkboxes {
      .checkbox__container {
        margin-bottom: 0;
      }
    }
    .text-input label {
      margin-bottom: 0.5rem;
    }

    .radio-button {
      label {
        margin-bottom: 1rem;
      }
      .radio-btn-container {
        margin-bottom: 1rem;
      }
    }

    .date-picker {
      label {
        margin-bottom: 1rem;
      }
    }

    a.file,
    .file-upload {
      background-color: $theme-circulair;
      padding-left: 1.25rem;
      padding-right: 0.9rem;
      p,
      label {
        color: $primary-blue;
        font-weight: 600;
        margin-bottom: 0;
      }
    }
    .file-upload {
      display: flex;
      align-items: center;
      position: relative;
      input[type="file"] {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        right: 0;
        width: 80px;
        padding: 0.5rem 80px;
        padding-left: calc(100% - 80px);
      }
      img {
        width: 1.25rem;
        margin: 0 0.4rem;
      }
    }
  }
  .buttons {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.6rem;
    .button__apply {
      margin-right: 0;
      width: 52%;
      height: unset;
      padding-top: 0.8rem;
      padding-bottom: 0.8rem;
      font-size: 1rem;
    }

    .active {
      background: $signin-box;
      color: $primary-blue;
      border: 1px solid $primary-blue;
      z-index: 2;
    }
    .button-left {
      &.active {
        margin-right: -1rem;
      }
    }
    .button-right {
      &.active {
        margin-left: -1rem;
      }
    }

    &.btn-inside-itemPanel {
      margin-top: 2.5rem;
      margin-bottom: 0;
      .button__apply {
        width: unset;
        margin-bottom: 0;
        height: unset;
      }
      .active {
        background-color: $grey-light;
      }
      .button-left {
        &.active {
          margin-right: 1rem;
        }
      }
      .button-right {
        &.active {
          margin-left: 1rem;
        }
      }
    }
  }
}
