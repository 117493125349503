@import "../../styles/variables.scss";

.modal-wrapper {
  background: rgba(18, 18, 18, 0.32);
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 0;
  min-height: 100%;
  min-width: 100%;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  display: none;

  .modal-container {
    background-color: #fff;
    width: 90%;
    max-width: 50rem;

    @include size-mobile {
      width: 80%;
    }

    @include size-tablet {
      width: 70%;
    }

    a img {
      display: block;
      text-align: end;
      padding-top: 1rem;
      padding-right: 1.5rem;
    }

    h1 {
      font-size: 1.75rem;
      margin-bottom: 2.5rem;
    }

    button {
      height: 3rem;
      background-color: $primary;
      color: #fff;
      width: 100%;
      border: none;
      border-radius: $default-border-radius-extra-small;
      cursor: pointer;
    }
    .close-popup-button {
      max-width: max-content;
      display: block;
      margin-right: 0;
      margin-left: auto;
      padding: 1rem 1.5rem;
      color: $primary;
      background-color: transparent;
      text-decoration: underline;
    }

    .about-content {
      margin-top: 3rem;
      margin-bottom: 3rem;

      .modal-close {
        width: 2rem;
        color: $primary;
        background-color: transparent;
        display: block;
        margin-right: 0;
        margin-left: auto;

        svg,
        img {
          width: 1rem;
        }
      }

      button + button {
        margin-top: 0.4rem;
      }

      @media (max-height: 600px) {
        margin-top: 0.5rem;
        margin-bottom: 2rem;
      }
    }

    .small-width {
      width: 90%;

      @include size-mobile {
        width: 80%;
      }

      @include size-tablet {
        width: 80%;
      }
    }
  }
}

.show-modal {
  display: flex;
}
