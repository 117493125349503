@import "../../styles/variables.scss";

.sdgs {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  background-color: $grey-light;

  .sdgs__container {
    padding-top: 1rem;
    display: flex;
    flex-wrap: wrap;
  }

  .sdg {
    padding-right: 3.125rem;
    margin-bottom: .5rem;

    .sdg__link {
      text-decoration: none;
      display: flex;
      align-items: center;
      font-weight: 400;
      font-size: 0.875rem;

      .sdg__thumbnail {
        width: 2.25rem;
        height: 2.25rem;
        margin-right: 0.625rem;
      }
    }
    a.sdg__link:hover {
      text-decoration: underline;
    }
  }
}