.content__with__image {
  display: flex;
  flex-direction: column;
  line-height: 1.6rem;
  .text__content {
    max-width: 100%;
    h5 {
      font-size: 1rem;
    }
  }
  img {
    max-width: 50%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-self: flex-start;
    margin-top: 1rem;
  }

  @include size-desktop {
    .text__content {
      max-width: 50%;
    }
    flex-direction: row;
    img {
      max-width: 50%;
      margin-left: 2rem;
      margin-right: 0;
    }
  }
}
