@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap);

@mixin button-type {
  font-weight: 600;
  font-size: 1.125em;
  color: $default-button-text-color;
}

@mixin link-type {
  font-weight: normal;
  font-size: 1em;
  color: #2f196b;
}

@mixin tag-type {
  font-weight: 500;
  font-size: 1.5em;
}
