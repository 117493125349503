@import "../../styles/variables.scss";

.home-public-sector {
  .container__small {
    max-width: 38.75rem;
  }

  .testimonials__wrapper {
    max-width: 38.75rem;
    margin: auto;
    padding-bottom: 0;
  }

  .header__home__title {
    flex-shrink: 1;
    max-width: 38.75rem;
  }

  .header__home__wrapper {
    padding: 2rem 0 4rem 0;
  }

  .header__home__image {
    img {
      padding-top: 3rem;
      max-width: 100%;
    }

    @include size-desktop {
      flex-basis: 40%;
    }
  }

  .home__content_block {
    padding: 2rem 0;
  }

  .bg-light {
    background-color: rgba($primary-blue, 0.05);
    padding: 4rem 0;
  }

  .content__list {
    padding: 4rem 0;
  }

  .content__partners {
    padding: 2rem 0 4rem 0;
  }

  // overwrite timeline styling
  .container__timeline {
    margin-left: auto;
    margin-right: auto;

    .timeline {
      margin-bottom: 0;
    }

    .timeline__item:not(.open) {
      .timeline__item_bullet {
        color: $white;
        background-color: $primary-blue;
      }
    }

    .timeline__title {
      h2 {
        font-size: 1.5rem;
        font-weight: 400;
      }

      p {
        font-size: 1rem;
      }

      h1.kop {
        color: $primary-pink;
        margin: 0;
      }
    }
  }
}
