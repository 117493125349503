:-ms-input-placeholder {
  color: #2f196b;
  color: #858585;
  opacity: 0.6;
}
::placeholder {
  color: #2f196b;
  color: #858585;
  opacity: 0.6;
}
input {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}
input {
  overflow: visible;
}
div,
span {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  vertical-align: baseline;
  background: transparent;
}
*,
*:after,
*:before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.react-select-container {
  position: relative;
  box-sizing: border-box;

  & > span {
    z-index: 9999;
    border: 0;
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    width: 1px;
    position: absolute;
    overflow: hidden;
    padding: 0;
    white-space: nowrap;
  }
}

.react-select__control {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: hsl(0, 0%, 100%);
  border-color: #2f196b;
  border-radius: 0;
  border-style: solid;
  border-width: 1px;
  box-shadow: none;
  cursor: default;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  min-height: 38px;
  outline: 0 !important;
  position: relative;
  -webkit-transition: all 100ms;
  transition: all 100ms;
  box-sizing: border-box;
}

.react-select__control:hover {
  border-color: hsl(0, 0%, 70%);
}

.react-select__input-container {
  margin: 3px;
  padding-bottom: 3px;
  padding-top: 3px;
  visibility: visible;
  color: #2f196b;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  display: inline-grid;
  grid-area: 1/1/2/3;
  grid-template-columns: 0 min-content;
  box-sizing: border-box;
}

.react-select__input-container:after {
  content: attr(data-value) " ";
  visibility: hidden;
  white-space: pre;
  grid-area: 1/2;
  font: inherit;
  min-width: 2px;
  border: 0;
  margin: 0;
  outline: 0;
  padding: 0;
}

.react-select__indicators {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  box-sizing: border-box;
}

.react-select__indicator-separator {
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  background-color: #eeebeb;
  margin-bottom: 12px;
  margin-top: 12px;
  width: 1px;
  box-sizing: border-box;
  margin: 0;
}

.react-select__indicator {
  color: #2f196b;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 12px;
  -webkit-transition: color 150ms;
  transition: color 150ms;
  box-sizing: border-box;

  svg {
    display: inline-block;
    fill: currentColor;
    line-height: 1;
    stroke: currentColor;
    stroke-width: 0;
  }
}

.react-select__indicator:hover {
  color: hsl(0, 0%, 60%);
}

.react-select__value-container {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 3px 12px;
  -webkit-overflow-scrolling: touch;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
}

.react-select__multi-value {
  background-color: #2f196b;
  border-radius: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: 3px;
  min-width: 0;
  box-sizing: border-box;
  color: #fff;
}

.react-select__multi-value__label {
  border-radius: 0;
  color: #fff;
  font-size: 85%;
  overflow: hidden;
  padding: 3px;
  padding-left: 6px;
  text-overflow: ellipsis;
  white-space: nowrap;
  box-sizing: border-box;
}

.react-select__multi-value__remove {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-left: 6px;
  padding-right: 6px;
  box-sizing: border-box;
}

.react-select__multi-value__remove:hover {
  background-color: #d8bdee;
  color: #2f196b;
}

.react-select__menu {
  top: 100%;
  background-color: hsl(0, 0%, 100%);
  border-radius: 0;
  box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1);
  margin-bottom: 8px;
  margin-top: 8px;
  position: absolute;
  width: 100%;
  z-index: 1;
  box-sizing: border-box;
}
.react-select__menu-list {
  max-height: 300px;
  overflow-y: auto;
  padding-bottom: 6px;
  padding-top: 6px;
  position: relative;
  -webkit-overflow-scrolling: touch;
  box-sizing: border-box;
}
.react-select__option {
  background-color: #d8bdee;
  color: inherit;
  cursor: default;
  display: block;
  font-size: inherit;
  padding: 12px 18px;
  width: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  box-sizing: border-box;
}
.react-select__option:active {
  background-color: #d8bdee;
}
.react-select__option {
  background-color: transparent;
  color: inherit;
  cursor: default;
  display: block;
  font-size: inherit;
  padding: 12px 18px;
  width: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  box-sizing: border-box;
}
.react-select__option:active {
  background-color: #d8bdee;
}
