@import "../../styles/variables.scss";

.project__box__fullwidth {
  background-color: $theme-aanbesteding;
  display: block;
  color: $primary-blue;
  text-decoration: none;
  border-radius: 0.25rem;
  padding: 1.25rem 1.5rem;

  .project__box__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.9rem;

    h4 {
      margin-bottom: 0;
    }
  }
}

.project__boxes {
  h3 {
    margin-bottom: 1rem;
  }

  .boxes__container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include size-mobile {
      flex-direction: row;
    }
  }
}

.halfwidth__box {
  width: 100%;
  min-height: 10rem;
  border-radius: 0.5rem;

  &:nth-child(1) {
    margin-bottom: 1rem;
  }

  @include size-mobile {
    width: 48.1%;

    &:nth-child(1) {
      margin-bottom: 0;
    }
  }
}
