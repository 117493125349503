.signin-accordion {
  &.account__apply {
    .text-input {
      input[type="file"] {
        border: none;
      }
    }

    .timeline__item_content .file-uploaded div p {
      margin-bottom: 0;
    }
    .file-upload-wrapper {
      //   background-color: red;
      display: flex;
      border: 1px dashed $primary-blue;
      border-radius: 0.25rem;
      background-color: white;
      justify-content: space-between;
    }
    button.remove-button {
      //   display: flex;
      //   background-color: red;
      background-color: transparent;
      border: none;
      padding: 1rem;
      margin-top: 1rem;
      margin-left: 1rem;
      position: relative;
      cursor: pointer;

      &:before {
        content: url("/assets/icons/close-purple.svg");

        display: block;
        position: absolute;
        top: 0;
        left: 0;
        height: 16px;
        width: 16px;
      }
      &:hover {
        opacity: 0.8;
      }
    }
  }
  .download__bar {
    background-color: white;
    border: 1px solid $primary-blue;
    border-radius: 0.25rem;
  }

  .timeline-review {
    .timeline__item_content {
      .item-text {
        margin-bottom: 1.7rem;
        h3 {
          font-size: 1rem;
          margin-bottom: 0.5rem;
        }
      }
      .info-text {
        margin-bottom: 1.7rem;
      }
      .checkbox__wrapper {
        .checkbox__container {
          cursor: auto;
          margin-bottom: 1rem;
        }
      }
      .external__link {
        cursor: pointer;
      }

      .header-timeline-signin {
        h3 {
          margin-bottom: 1rem;
        }
      }
      label {
        margin-bottom: 1.7rem;
      }
    }
    .accordion__heading {
      .timeline__item_bullet {
        &.review_bullet {
          border-color: $checkbox-icon;
        }
      }
    }
  }
}
