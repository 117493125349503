@import "../../styles/variables.scss";

.financebox {
  background-color: $default-panel-background-color;
  padding: 0.5rem;
  padding-left: 1rem;
  border-radius: 0.25rem;
  display: block;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  flex-basis: 0;
  position: relative;
  margin: 0 0 1rem 0;
}

.financebox__content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 0.8rem;
}

.financebox__text {
  background-color: $default-panel-background-color;
  margin-right: $default-margin;
  h4 {
    display: block;
    font-weight: 600;
    padding-bottom: 0.8rem;
  }
  span {
    display: block;
    font-weight: 400;
  }
}

.financebox__countdown {
  background-color: #fff;
  border-radius: 0.25rem;
  position: relative;
  font-weight: 600;
  font-size: 1.5rem;
  height: 4.5rem;
  line-height: 4.5rem;
  width: 50px;
  &:before {
    content: "";
    display: block;
    background-image: url("/assets/icons/pricetag.svg");
    background-size: 32px 32px;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center 30%;
    position: absolute;
  }
  span {
    display: block;
    font-weight: 400;
    font-size: 0.8rem;
    margin-top: 1.5rem;
    text-align: center;
  }
}
.financebox__text_link {
  color: $default-text-color;
}
