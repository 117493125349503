@import "../../styles/variables.scss";

.navbar__wrapper > .container {
  z-index: 100;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.25rem 1rem;

  .navbar__logos {
    z-index: 3;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2rem;

    @include size-large {
      gap: 3rem;
    }

    .logo img {
      width: 10rem;
    }

    .logo-right {
      text-decoration: none;

      p {
        display: none;
        font-size: 0.57rem;
        white-space: nowrap;
        padding-bottom: 0.2rem;

        @include size-desktop {
          display: block;
        }
      }

      img {
        width: 4.5rem;
      }
    }
  }

  .navbar__mobile_toggle {
    background: none;
    color: inherit;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    z-index: 4;
    border-radius: 4px;
    display: flex;
    align-items: center;
    gap: .75rem;
    padding: .75rem;
    border: 1px solid $primary-blue;

    @include size-desktop {
      display: none;
    }

    .toggle_icon {
      width: 1.25rem;
      height: 1rem;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
    
      span {
        transition: all 0.3s ease-in-out;
        background-color: $primary-blue;
        height: 1px;
        width: 100%;
        display: block;

        &:nth-child(1) {
          transform: rotate(0deg);
        }
    
        &:nth-child(2) {
          opacity: 1;
        }
    
        &:nth-child(3) {
          transform: rotate(0deg);
        }
      }
    }
  }

  .navbar__menu {
    display: none;

    @include size-desktop {
      display: flex;
      position: relative;

      // move account item to back of list on desktop
      .navbar__items {
        display: flex;
        flex-direction: row-reverse;
        gap: 1rem;

        @include size-large {
          gap: 2rem;
        }
      }
    }

    .navbar__list {
      display: flex;
      list-style-type: none;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      gap: 1rem;

      @include size-large {
        gap: 2rem;
      }
    
      li {
        a {
          font-weight: 600;
          color: $default-text-color;
          white-space: nowrap;
          display: flex;
          align-items: center;
          gap: .3rem;

          div {
            display: flex;
            align-items: center;
            gap: .3rem;
            white-space: nowrap;
          }
        }
      }
    }
  }

  li.navbar__language {
    margin-left: 1rem;

    @include size-large {
      margin-left: 2rem;
    }
  }

  li.navbar__submenu_item {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 1rem 0; // add vertical padding to keep both hover and flex center

    .navbar__submenu {
      display: none;
      box-shadow: 0;
      border-radius: $default-border-radius-extra-small;
      transition: all 5.25s ease-out;

      a {
        padding: .5rem 1.5rem;
        color: $default-text-color;
        font-weight: 300;
        font-size: 0.8rem;
        text-decoration: underline;
        display: flex;
        align-items: center;
        transition: all 0.45s ease-out;
        white-space: nowrap;

        @include size-desktop {
          justify-content: flex-end;
        }

        &:hover, &:active, &:focus {
          font-weight: 500;
          background-color: rgba($soft-purple, .1);
        }
      }
    }

    &:hover {
      .navbar__submenu {
        display: flex;
        flex-direction: column;
        z-index: 1000;
        transition: all 5.25s ease-out;

        @include size-desktop {
          position: absolute;
          top: 3rem;
          right: -.5rem;
          display: flex;
          flex-direction: column;
          z-index: 1000;
          background-color: $theme-aanbesteding;
          transition: all 5.25s ease-out;
        }
      }
    }
  }
}

  // when mobile menu is open
@include size-up-to-desktop {
  .navbar__wrapper.menu-expanded {
    z-index: 9999;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
  
    .container {
      flex-wrap: wrap;
      
      .navbar__menu {
        display: block;
        width: 100%;
        padding-top: 2rem;
  
        .navbar__list {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: stretch;
          gap: 0;
  
          li {
            padding: 0;
            border-bottom: 1px solid $grey-input;
            
            a {
              width: 100%;
              padding: 1rem;
              font-size: 1.2rem;
              display: flex;
              justify-content: space-between;
            }
          }

          li.navbar__language {
            margin-left: 0;
            border-bottom: none;
            margin-top: 2rem;
            align-self: center;
          }
        }
      }
  
      .navbar__mobile_toggle {
        color: #fff;
        background-color: $primary-blue;
      }
    
      .toggle_icon {
        height: 1.25rem;
  
        span {
          background-color: #fff;
  
          &:nth-child(1) {
            transform: rotate(45deg) translateX(.5rem) translateY(.3125rem);
          }
    
          &:nth-child(2) {
            height: 0;
            opacity: 0;
          }
    
          &:nth-child(3) {
            transform: rotate(135deg) translateX(-.5rem) translateY(.3125rem);
          }
        }
      }
    }
  }
}
