@import "../../styles/variables.scss";

$my-colors: (
  $theme-mobiliteit,
  $theme-gezondheid,
  $theme-nieuwetechnologie,
  $theme-openbareruimte,
  $theme-energy,
  $theme-onderwijs,
  $theme-aanbesteding,
  $theme-circulair,
  $theme-aanbesteden-baby
);

@for $i from 1 through length($my-colors) {
  .content__list__items .item:nth-of-type(#{length($my-colors)}n + #{$i}) {
    .icon {
      background-color: nth($my-colors, $i);
    }
  }
}

.embed__responsive__video {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
  margin: 0.5rem auto;
  margin-bottom: 3rem;
  /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) 56.25% */
  /* 4:3 Aspect Ratio (divide 3 by 4 = 0.75) 75% */
  .responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
}

.newsletter__box {
  width: 100%;
  position: relative;
  background-color: $grey-light;
  padding: 1.6rem;
  border-radius: 0.5rem;
  margin-bottom: 1rem;

  .newsletter__box_image {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 1;
  }

  .newsletter__content {
    position: relative;
    z-index: 2;

    .introduction {
      padding-top: 1rem;
      opacity: 0.5;
    }
  }

  input.form-control {
    background-color: #fff;
  }
}

.content__list {
  padding: 3rem 0;

  .container__small {
    margin: auto;

    @include size-tablet {
      margin: 0;
    }
  }

  h3 {
    font-weight: inherit;
  }

  .content__list__items {
    padding: 1rem 0;
    // display: flex;
    // flex-direction: column;
    // justify-content: space-between;
    gap: 1rem;

    @include size-tablet {
      flex-direction: row;
      gap: 2rem;
    }

    .item {
      flex-basis: 100%;

      .icon {
        margin: 1rem 0;
        border-radius: 50%;
        width: 2.5rem;
        height: 2.5rem;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          max-width: 1.2rem;
        }
      }
    }
  }
}

.content__blogs,
.content__projects,
.content__list,
.content__contact,
.content__partners {
  display: flex;
  flex-direction: column;
  align-items: center;

  & > h2 {
    text-align: center;
  }
  h1 {
    font-weight: 400;
    text-align: center;
  }

  .cards__grid {
    margin: 1.5rem auto;

    & > * {
      margin: 0 0 0 0;
    }

    &.max_2 {
      @include size-tablet {
        grid-template-columns: repeat(2, 1fr);
      }

      @include size-large {
        width: 78%;
        grid-template-columns: repeat(2, 1fr);
      }
    }

    &.max_1 {
      @include size-tablet {
        width: 60%;
        grid-template-columns: repeat(1, 1fr);
      }

      @include size-large {
        width: 40%;
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }

  & > a {
    background-color: #fff;
    border: 1px solid rgba($primary-blue, 0.32);
    padding: 0.5rem 2rem;
    border-radius: $default-border-radius-extra-small;
    text-decoration: none;

    &:hover,
    &:focus {
      border-color: $primary-blue;
    }
  }
}

.content__list {
  .container__small {
    align-self: flex-start;
  }
}

.content__image__list {
  display: flex;
  flex-direction: column;
  align-items: center;

  .content__basic {
    margin-bottom: 0;
  }

  .items {
    padding: 2rem 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;

    .item {
      width: 8.6rem;
      text-align: center;
      .image {
        width: 8.6rem;
        height: 8.6rem;
        margin-bottom: 1rem;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;

        img {
          min-width: 100%;
          min-height: 100%;
          object-fit: cover;
        }
      }
      &.item-full {
        .image {
          border-radius: 0;
          img {
            object-fit: contain;
          }
        }
      }
    }
  }
}

.content__contact {
  padding: 4rem 0;
}

.content__action__button {
  display: flex;

  &.center {
    justify-content: center;
  }

  a.primary,
  a.secondary {
    margin-bottom: 0.5rem;
    border: 1px solid rgba($primary-blue, 0.32);
    padding: 0.5rem 2rem;
    border-radius: $default-border-radius-extra-small;
    text-decoration: none;
  }

  a.secondary.external__link,
  a.primary.external__link {
    text-decoration: none !important;
  }

  a.primary {
    background-color: $primary-blue;
    color: #fff;
  }

  a.secondary {
    background-color: #fff;

    &:hover,
    &:focus {
      border-color: $primary-blue;
    }
  }
}

.list__LocationMarkersYellow {
  ul li {
    background-image: url("/assets/icons/project_list_yellow.svg");
  }
}

.list__LocationMarkersGreen {
  ul li {
    background-image: url("/assets/icons/project_list_green.svg");
  }
}
