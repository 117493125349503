@import "../../styles/variables.scss";

.form-control {
  width: 100%;
  background-color: $grey-input;
  margin-bottom: 1rem;
  border: none;
  outline: none;
  padding: 1rem 0.5rem;
}

.login-button {
  width: 100%;
  height: 3rem;
  border: none;
  background-color: $primary-blue;
  color: #fff;
  border-radius: $default-border-radius-extra-small;
  cursor: pointer;
}

.invalidCredentials {
  padding: 1rem 0;
  color: #c00;
}

.hidden {
  display: none;
}

.form-container > div:not(:last-child) {
  margin-bottom: 2.75rem;
}
