.letters {
  margin: 1rem 0;
  a {
    display: inline-block;
    font-size: 1.25rem;
    margin-right: 1.8rem;
    margin-bottom: 0.5rem;
  }
}

.definition {
  padding-top: 2rem;
}

.inlinea {
  text-decoration: none;
  cursor: default;
}

.inlinea:hover {
  ::after {
    cursor: pointer;

    content: " #";
    color: #999;
    font-size: 0.6rem;
    vertical-align: top;
  }
}
