@import "../../styles/variables.scss";

.qna__loket {
  .search__kennisbank {
    margin-bottom: 2rem;
  }

  .item__question {
    h4 {
      font-size: 1.125rem;
      font-weight: 600;
    }

    .answer {
      margin-top: 0.5rem;
      background-color: $grey-light;
      border-radius: $default-panel-radius-small;
      padding: 1.25rem 1.5rem;
      text-align: left;
    }
  }

  .item__title {
    margin-bottom: 1rem;

    h4 {
      font-size: 1.25rem;
      font-weight: 500;
      margin-bottom: 1rem;
    }
    p {
      margin-bottom: 1rem;
    }
    a {
      font-weight: 300;
      margin-bottom: 1rem;
      display: block;
    }
  }

  .qna__form__wrapper {
    margin-bottom: 4rem;
    margin-top: 2rem;
  }
}
