@import "../../styles/variables.scss";

.header__procedure {
  display: flex;
  flex-direction: column-reverse;
  margin-bottom: 5rem;
  border-radius: $default-border-radius-large $default-border-radius-large 0 0;
  box-shadow: 0px 0px 80px rgba(0, 0, 0, 0.16);
  padding: 2rem;
  padding-top: 0.5rem;

  .title__and__intro {
    align-self: center;

    h3 {
      font-size: 1.75rem;
      margin-bottom: 1.25rem;
    }

    div {
      max-width: 100%;

      @include size-tablet {
        max-width: 75%;
      }
    }
  }

  img {
    align-self: center;
    margin: 1rem auto;
    max-width: 100%;
  }

  @include size-mobile {
    flex-direction: row;
    padding: 0 6.5rem;

    h3 {
      margin-top: 2rem;
    }

    p {
      margin-bottom: 2rem;
    }

    img {
      align-self: end;
    }
  }
}
