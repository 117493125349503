@import "../../styles/variables.scss";

.imagebox {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  justify-content: center;
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.imagebox__image {
  width: 100%;
  height: 100%;
}

.imagebox__label {
  color: $grey;
  font-size: 1rem;
  font-weight: 300;
  margin-top: $default-margin;
  margin-bottom: $default-margin;
}
