@import "../../styles/variables.scss";

.border__item {
  display: block;
  text-decoration: none;
  color: $primary-blue;
  border: 1px solid $primary-blue;
  border-radius: $default-border-radius-extra-small;
  padding: 1rem 1.5rem;
  line-height: 1.6rem;
  margin: 1rem auto;
  .piano-logo {
    width: 5.4rem;
    max-height: 4rem;
    margin-bottom: 1rem;
  }
  a {
    text-decoration: underline;
    font-weight: 300;
  }

  p {
    margin-bottom: 1rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
