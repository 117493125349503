@import "../../styles/variables.scss";

.projectcardWrapper {
  @include base-card;
  border-radius: $default-panel-radius-small;
  margin-bottom: 2.5 * $default-margin;
  overflow: hidden;
}

.projectcard {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  margin: auto;
  font-size: 1rem;
  border-width: 0;

  flex-direction: column-reverse;
  box-shadow: 0px 0px 56px rgba(17, 17, 17, 0.16);
  .deadlinebox,
  .cta {
    width: auto;
  }

  .process-bar {
    border-bottom-left-radius: $default-panel-radius-small;
    border-bottom-right-radius: $default-panel-radius-small;
    overflow: hidden;
  }

  .arrow__link {
    &:after {
      background-image: url("/assets/icons/arrow_right_white.svg");
      background-size: 0.4rem;
      background-repeat: no-repeat;
      background-position: center;
      width: 0.4rem;
      height: 0.6rem;
      content: "";
      margin-left: 0.75rem;
      margin-top: 0.1rem;
    }
  }

  @include size-desktop {
    flex-direction: row;
  }
  @include size-tablet {
    flex-direction: row;
  }
}

.projectcard__content {
  background-color: #fff;
  border-radius: $default-panel-radius-small 0 0 $default-panel-radius-small;
  padding: 2rem;
  padding-left: 2rem;
  padding-right: 1.3rem;
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.projectcard__content_title {
  margin: 0.5rem 0 0.5rem 0;
  font-size: 1.25rem;
  font-weight: 500;
}

.projectcard__content_footer {
  display: flex;
  flex-direction: column-reverse;
  align-items: stretch;
  gap: 2rem;
  margin-top: 2rem;

  .cta,
  .button__cta {
    @include button-type;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0px;
    border-radius: $default-border-radius-extra-small;
    padding: 1rem;
    color: white;
    text-decoration: none;
    background-color: $primary-blue;
    order: 2;
    width: 100%;
  }
  .deadlinebox {
    margin-bottom: 0;
    order: 1;
    width: 100%;
  }

  .ctas {
    padding-top: 1rem;
    width: 100%;
    display: flex;
    gap: 0.5rem;

    .button__cta {
      color: $primary-blue;
      background-color: $white;
      border: 1px solid rgba($primary-blue, 0.32);

      img {
        margin-right: 0.5rem;
      }

      &:hover,
      &:focus {
        border-color: $primary-blue;
      }
    }
  }
}

.projectcard__thumbnail {
  width: 36%;
  display: block;
  background-color: $primary-lightblue;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 12rem;
  position: relative;

  .overlayimage {
    position: absolute;
    bottom: 0;
    max-width: 100%;
    right: 0;
    max-height: 100%;
  }

  @include size-tablet {
    max-width: 36%;
    background-position: center bottom;
    height: auto;
  }
}

.projectcardWrapper.vertical {
  box-shadow: 0px 4px 4px $primary-purple;

  &.loket {
    box-shadow: 0px 4px 4px $primary-pink;
  }

  .projectcard {
    flex-direction: column-reverse;

    .projectcard__content {
      padding: 1rem;
      border-radius: 0 0 0 0;
    }

    .deadlinebox {
      margin: 0 0 0 0;
    }

    .projectcard__content_footer {
      flex-direction: column-reverse;
      align-items: stretch;

      .ctas {
        padding-top: 1rem;
        width: 100%;
        display: flex;
        gap: 0.5rem;

        .button__cta {
          color: $primary-blue;
          background-color: $white;
          border: 1px solid rgba($primary-blue, 0.32);

          img {
            margin-right: 0.5rem;
          }

          &:hover,
          &:focus {
            border-color: $primary-blue;
          }
        }
      }
    }

    .projectcard__thumbnail {
      height: 12rem;
      background-position: center;
      max-width: 100%;
    }
  }
}
