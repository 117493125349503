/* STYLING FOR CONTENT ON RIGHT SIDE UNDER HEADER, BUTTONS AND HELP SECTION */

/* buttons under header on right side "download als word" and "leesweergave" */
.col3 {
  width: 0%;
  display: none;

  @include size-desktop {
    display: block;
    width: 25%;
    padding-left: 0.5rem;
  }

  @include size-large {
    display: block;
    width: 25%;
    padding-left: 1rem;
  }

  @include size-extra-large {
    padding-left: 2rem;
  }

  .buttons-right-side {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-bottom: 1.6rem;
    .button__apply {
      margin-right: 0;
      background-color: #fff;
      color: $primary-blue;
      border: 1px solid $primary-blue;
      font-size: 1rem;
      min-width: 90%;
      height: unset;
      padding-top: 0.8rem;
      padding-bottom: 0.8rem;
      @include size-large {
        min-width: 80%;
      }  &.button__red {
        background-color: #c00;
      }
    }
  }
}

/* inschrijving help box on right side */
.help-box-container {
  width: 0%;
  display: none;
  position: absolute;
  top: 0;
  left: 100%;

  @include size-desktop {
    &.show-help-box {
      display: block;
    }
    width: 50%;
    padding-left: 0.5rem;
  }

  @include size-large {
    padding-left: 1rem;
  }

  .help-box {
    background-color: $signin-box;
    border-radius: $default-border-radius-small;
    padding: 1.5rem;

    .buttons {
      display: flex;
      justify-content: space-between;
      margin-bottom: 1.6rem;
      .button__apply {
        margin-right: 0;
        width: 52%;
      }

      .active {
        background: $signin-box;
        color: $primary-blue;
        border: 1px solid $primary-blue;
        z-index: 2;
      }
      .button-left {
        &.active {
          margin-right: -1rem;
        }
      }
      .button-right {
        &.active {
          margin-left: -1rem;
        }
      }
    }

    /* custom timeline in help section aside right */
    .timeline {
      &.timeline-help {
        margin-bottom: 1.3rem;
        .timeline__item {
          padding-left: 0;
          &:after {
            height: 0;
          }
          &:not(.open) {
            background-color: transparent;
          }
          &.open {
            background-color: transparent;
            .timeline__item_title {
              &:after {
                background-image: url("/assets/icons/arrow_up_blue.svg");
              }
            }
          }
          .timeline__item_content {
            margin: 0;
            margin-top: 1rem;
          }
          .timeline__item_title {
            h5 {
              padding-right: 2.6rem;
              font-size: 0.875rem;
              font-weight: 600;
            }
            &:after {
              background-color: #fff;
              background-size: 0.875rem;
              width: 2.5rem;
              height: 2.5rem;
              background-image: url("/assets/icons/arrow_down_blue.svg");
            }
          }
        }
      }
    }

    .divider {
      opacity: 0.12;
      border: 0.5px solid #2f196b;
      margin-top: 1.25rem;
      margin-bottom: 1.25rem;
    }
  }
}
