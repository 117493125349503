.info__block {
  h1 {
    margin-bottom: 1rem;
  }
  p {
    margin-bottom: 1rem;
  }
  button {

    margin-top: 1rem;
    display: inline-block;
    &.dark-button {
      background-color: $primary-blue;
      color: white;
    }
    &.light-button {
      background-color: white;
      color: $primary-blue;
      border: 1px solid $primary-blue;
    }
    &.danger-button {
      background-color: $signin-error;
      color: white;
    }
  }
}
