@import "../../styles/variables.scss";

.collapsebox {
  margin-bottom: $default-panel-padding;

  &:last-child {
    margin-bottom: 1rem;
  }

  ul {
    list-style-type: disc !important;
    padding: 0;

    li {
      flex-direction: row;
      padding: 0.2rem 0 0.2rem 0.5rem !important;
      margin-left: 1rem !important;
      align-items: center;
      min-height: auto;
      list-style: disc !important;
      list-style-position: outside !important;
    }
  }
}

.collapsebox__button {
  @include button-type;
  @include base-panel;
  position: relative;
  margin-bottom: 0px;
  display: block;
  width: 100%;
  // height: 4rem;
  padding-right: 2rem;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  padding-left: 1.5rem;
  line-height: 2rem;
  margin-top: 1rem;
  text-align: left;
  transition: all 0.25s ease-out;

  &:after {
    content: "";
    display: block;
    position: absolute;
    background-position: center;
    right: $default-panel-padding;
    top: 1.6rem;
    height: 20px;
    width: 20px;
    background-image: url("/assets/icons/arrow_down_blue.svg");
    background-repeat: no-repeat;
  }

  &.open {
    border-radius: $default-panel-radius-small $default-panel-radius-small 0 0;
    background-color: $default-panel-background-color;

    &:after {
      background-image: url("/assets/icons/arrow_up_blue.svg");
    }
  }
}

.collapsebox__content {
  @include base-panel;
  display: none;
  visibility: collapse;
  margin-top: 0px;
  padding-top: $default-panel-padding;
  padding-bottom: $default-panel-padding;
  border-radius: 0 0 $default-panel-radius-small $default-panel-radius-small;
  background-color: #fff;
  transition: all 0.25s ease-out;

  &.open {
    background-color: $default-panel-background-color;
    height: auto;
    display: block;
    visibility: visible;
    padding-left: 1.625rem;

    .content__basic {
      margin-top: 0 !important;
      img {
        width: 100%;
        margin-top: 2rem;
      }

      .pdf {
        background-color: #fff;
      }
    }
  }
}
