@mixin input-form-basics() {
  background-color: #fff;
  border: 1px solid $primary-blue;
  padding: 0.67rem 1rem;
  color: $primary-blue;
  font-size: 1rem;
  outline: none;
  &:focus {
    border: 1px solid $theme-circulair;
  }
}

@mixin input-hide() {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

@mixin button-container() {
  display: block;
  position: relative;
  cursor: pointer;
}

@mixin custom-checkmark($border-color, $background-color) {
  position: absolute;
  top: 0;
  left: 0;
  border: 1px solid $border-color;
  background-color: $background-color;
}
::placeholder {
  color: $primary-blue;
  color: $signin-middle-grey;
  opacity: 0.6;
}

@mixin form-item-container() {
  margin-bottom: 2.3rem;
  position: relative;

  .form-error {
    position: absolute;
    left: 0;
    font-size: 0.875rem;
    color: $signin-error;

    &.type-text,
    &.type-email {
      bottom: -0.875rem;
    }
    &.type-textarea,
    &.type-input {
      bottom: -1.4375rem;
    }
  }
}

// mixin for validation styling in from elements whan content entered is valid or not
@mixin show-validation($validation, $icon-position-height) {
  background-repeat: no-repeat;
  background-position: 98% $icon-position-height;
  padding-right: 3rem;
  @if $validation == "error" {
    border: 1px solid $signin-error;
    background-image: url("/assets/icons/signin-form-error.svg");
    color: $signin-error;
  }
  @if $validation == "valid" {
    border: 1px solid $signin-valid;
    background-image: url("/assets/icons/signin-form-correct.svg");
    color: $signin-valid;
  }
}

/* CUSTOM RADIO BUTTON START */
.radio-button {
  @include form-item-container();

  // show validation colors
  &.show-error .radio-btn-checkmark {
    border-color: $signin-error;
  }
  &.show-correct .radio-btn-checkmark {
    border-color: $signin-valid;
  }
  /* The radio-button-container */
  .radio-btn-container {
    @include button-container();
    padding-left: 3.1875rem;
    margin-bottom: 1rem;
    font-size: 1rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  /* Hide the browser's default radio button */
  .radio-btn-container input {
    @include input-hide();
  }

  /* Create a custom radio button */
  .radio-btn-checkmark {
    @include custom-checkmark($primary-blue, #fff);
    height: 19.92px;
    width: 19.92px;
    border-radius: 50%;
    @include size-mobile {
      width: 20.4px;
      height: 20.4px;
    }
    @include size-tablet {
      width: 21.6px;
      height: 21.6px;
    }
    @include size-desktop {
      width: 24px;
      height: 24px;
    }
  }

  /* On mouse-over, add a blue background color */
  .radio-btn-container:hover input ~ .radio-btn-checkmark {
    background-color: $primary-blue;
  }

  /* When the radio button is checked, add a white background */
  .radio-btn-container input:checked ~ .radio-btn-checkmark {
    background-color: #fff;
  }

  /* Create the indicator (the dot/circle - hidden when not checked) */
  .radio-btn-checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the indicator (dot/circle) when checked */
  .radio-btn-container input:checked ~ .radio-btn-checkmark:after {
    display: block;
  }

  /* Style the indicator (dot/circle) */
  .radio-btn-container .radio-btn-checkmark:after {
    width: 13.28px;
    height: 13.28px;
    top: 2.2px;
    left: 2.2px;
    border-radius: 50%;
    background: $primary-blue;
    @include size-mobile {
      width: 13.6px;
      height: 13.6px;
      top: 2.4px;
      left: 2.4px;
    }
    @include size-tablet {
      width: 14.4px;
      height: 14.4px;
      top: 2.6px;
      left: 2.6px;
    }
    @include size-desktop {
      width: 16px;
      height: 16px;
      top: 3px;
      left: 3px;
    }
  }
}

/* CUSTOM CHECKBOX */

.checkbox__wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 0.8rem;

  p {
    font-size: 0.9rem;
  }

  /* Customize the label (the container) */
  .checkbox__container {
    @include button-container();
    padding-left: 2rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    /* Hide the browser's default checkbox */
    input {
      @include input-hide();
      height: 1rem;
      width: 1rem;
      top: 0;
      left: 0;

      /* On focus, add a grey background color */
      &:focus ~ .checkmark {
        background-color: $primary-blue;
      }
    }

    /* Create a custom checkbox */
    .checkmark {
      @include custom-checkmark($primary-blue, $theme-aanbesteding);
      height: 1rem;
      width: 1rem;
      border-radius: 0.125rem;
      transition: background-color 0.2s ease-in-out;
      top: 0.15rem;
    }

    /* On mouse-over, add a grey background color */
    &:hover input ~ .checkmark {
      background-color: $primary-blue;
    }

    /* When the checkbox is checked, add a $primary-blue background */
    input:checked ~ .checkmark {
      background-color: $primary-blue;
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }

    /* Show the checkmark when checked */
    input:checked ~ .checkmark:after {
      display: block;
    }

    .checkmark:after {
      left: 0.3rem;
      top: 0.1rem;
      width: 0.3rem;
      height: 0.6rem;
      border: solid #ffffff;
      border-radius: 0.125rem;
      border-width: 0 0.125rem 0.125rem 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
}

.checkbox-button {
  @include form-item-container();

  &.show-error .checkmark {
    border-color: $signin-error;
  }
  &.show-correct .checkmark {
    border-color: $signin-valid;
  }
}

/* DROPDOWN SELECT */
.dropdown {
  position: relative;
  @include form-item-container();

  .dropdown-select-wrapper {
    position: relative;
  }

  .dropdown-select-wrapper:after {
    content: url("/assets/icons/arrow_down_blue.svg");
    width: 0.8em;
    height: 0.5em;
    position: absolute;
    top: 0.6rem;
    right: 1rem;
  }
  select {
    /* A reset of styles, including removing the default dropdown arrow */
    appearance: none;
    @include input-form-basics();
    line-height: inherit;
    padding-right: 1.8rem;
    margin: 0;
    width: 100%;
    font-size: 1rem;

    .custom-option {
      color: $primary-blue;
      background-color: #fff;
    }
  }

  &.show-error select {
    border-color: $signin-error;
  }
  &.show-correct select {
    border-color: $signin-valid;
  }
}

/* DATE PICKER */
.date-picker {
  @include form-item-container();
  input[type="text"] {
    @include input-form-basics();
    line-height: 1.535rem;
    width: 100%;
  }

  &.show-error {
    input[type="text"] {
      @include show-validation("error", 50%);
    }
  }
  &.show-correct {
    input[type="text"] {
      border: 1px solid $signin-valid;
    }
  }
}

/* TEXT INPUT */
.text-input {
  @include form-item-container();

  input[type="file"] {
    width: 100%;
    background-color: #fff;
    padding: 0.52rem 0.5rem;
    border: 1px dashed $primary-blue;
    border-radius: 0.25rem;
    color: $primary-blue;
  }
  input[type="file"]::file-selector-button {
    border: 2px solid $primary-blue;
    padding: 0.2em 0.4em;
    border-radius: 0.2em;
    background-color: $primary-blue;
    color: #fff;
    cursor: pointer;
    transition: 1s;
  }
  input[type="file"]::file-selector-button:hover {
    background-color: #81ecec;
    border: 2px solid #00cec9;
  }
  // .type-file-upload {
  //   ::-webkit-file-upload-button {
  //     visibility: hidden;
  //   }
  //   ::before {
  //     content: "Select some files";
  //     display: inline-block;
  //     background: linear-gradient(top, #f9f9f9, #e3e3e3);
  //     border: 1px solid #999;
  //     border-radius: 3px;
  //     padding: 5px 8px;
  //     outline: none;
  //     white-space: nowrap;
  //     -webkit-user-select: none;
  //     cursor: pointer;
  //     text-shadow: 1px 1px #fff;
  //     font-weight: 700;
  //     font-size: 10pt;
  //   }
  //   &:hover::before {
  //     border-color: black;
  //   }
  //   &:active::before {
  //     background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
  //   }
  // }

  input[type="text"],
  input[type="email"] {
    @include input-form-basics();
    margin-bottom: 1rem;
    width: 100%;
  }
  label {
    margin-bottom: 0.5rem;
  }
  &.show-error {
    input[type="text"],
    input[type="email"] {
      @include show-validation("error", 50%);
    }
  }
  &.show-correct {
    input[type="text"],
    input[type="email"] {
      @include show-validation("valid", 50%);
    }
  }
}

/* TEXTAREA INPUT */
.textarea-input {
  @include form-item-container();
  textarea {
    @include input-form-basics();
    width: 100%;
  }
  &.show-error {
    textarea {
      @include show-validation("error", 0.5rem);
    }
  }
  &.show-correct {
    textarea {
      @include show-validation("valid", 1rem);
    }
  }
}

/* DOWNLOAD BAR */
.download-bar {
  @include form-item-container();
  .download-link {
    @include input-form-basics();
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    background-color: #fff;
    padding: 0.56rem 2rem 0.56rem 1rem;
    border: 1px solid $primary-blue;
    border-radius: 0.25rem;
    color: $primary-blue;
    text-decoration: none;
    img {
      width: 1.4275rem;
      margin-right: 1rem;
    }
    &:after {
      content: url("/assets/icons/download-bar-signin.svg");
      position: absolute;
      right: 1rem;
      top: 0.8rem;
    }
  }
}

/* RANGE INPUT */
.range-input {
  input[type="range"] {
    width: 100%;
    -webkit-appearance: none;
    border-radius: 1rem;
    height: 0.4rem;
    border: 1px solid $primary-blue;
    background-color: $primary-blue;
  }
  ::-moz-range-thumb,
  ::-webkit-slider-thumb {
    -webkit-appearance: none;
    background-color: #fff;
    border: 1px solid $primary-blue;
    width: 1rem;
    height: 1rem;
    border-radius: 1rem;
    cursor: pointer;
  }
  ::-moz-range-track {
    width: 100%;
    height: 0.4rem;
    color: transparent;
    cursor: pointer;
    background-color: $primary-blue;
    border-color: transparent;
    border-radius: 1rem;
  }
}

/* NUMBER INPUT */
.number-input {
  @include form-item-container();
  input[type="number"] {
    @include input-form-basics();
    width: 100%;
  }
  &.show-error {
    input[type="number"] {
      @include show-validation("error", 50%);
    }
  }
  &.show-correct {
    input[type="number"] {
      @include show-validation("valid", 50%);
    }
  }
}
.file-uploaded {
  margin: 0.2rem 0;
  border: 1px solid $primary-blue;
  padding: 0.7rem 0;
  border-radius: 0.25rem;
  background-color: white;
  width: 100%;
  display: flex;
  align-items: center;

  img {
    width: 1.8rem;
    display: block;
    margin: 0 0.7rem;
  }

  .filename {
    width: 100%;
    display: block;
    font-weight: normal;
    white-space: nowrap;
    overflow: hidden;
  }

  button {
    border: none;
    padding: 1rem;
    position: relative;
    cursor: pointer;
    background-color: transparent;
    margin-left: 0.3rem;

    &:before {
      content: url("/assets/icons/close-purple.svg");
      display: block;
      position: absolute;
      top: 0.4rem;
      left: 0;
      height: 16px;
      width: 16px;
    }
  }
}
