@import "../../styles/variables.scss";

.process-bar {
  background-color: $signin-process;
  height: 4rem;
  position: relative;

  .completed {
    height: 100%;
    background-color: $checkbox-icon;
  }

  p {
    position: absolute;
    top: 1.2rem;
    left: 2rem;
    color: #fff;
    font-size: 1rem;
    font-weight: 700;
  }
}