@import "../../styles/variables.scss";

.blogcard__wrapper {
  @include base-card;
  background-color: #fff;
  border-radius: 0.25rem;
  box-shadow: 0px 0px 56px rgba(17, 17, 17, 0.16);
  text-decoration: none;
  position: relative;
  top: 0;
  transition: all ease 0.3s;

  &:hover,
  &:focus {
    top: -5px;
    box-shadow: 0px 0px 56px rgba(17, 17, 17, 0.3);
  }
}

.blogcard {
  width: 100%;
  height: 100%;
  font-size: 1rem;
  display: flex;
  flex-direction: column;

  .blogcard__image {
    height: 14rem;
    overflow: hidden;
    border-top-right-radius: 0.25rem;
    border-top-left-radius: 0.25rem;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .blogcard__content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: 1.25rem;
    gap: 1rem;

    .blogcard__content_meta {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      opacity: 0.5;
      font-size: 0.8rem;
    }

    .blogcard__content_title {
      margin: 0;
      font-size: 1.25rem;
      font-weight: 500;
    }

    .blogcard__content_footer {
      flex-grow: 1;
      flex-shrink: 1;
      display: flex;
      justify-content: flex-end;
      flex-direction: column;
      align-items: flex-start;

      .arrow__link {
        @include button-type;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: $default-border-radius-extra-small;
        padding: 0.6rem 2rem;
        color: white;
        text-decoration: none;
        background-color: $primary-blue;

        &:after {
          background-image: url("/assets/icons/arrow_right_white.svg");
          background-size: 0.4rem;
          background-repeat: no-repeat;
          background-position: center;
          width: 0.4rem;
          height: 0.6rem;
          content: "";
          margin-left: 0.75rem;
          margin-top: 0.1rem;
        }
      }
    }
  }
}
