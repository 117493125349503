@import "../../styles/variables.scss";

$current-entries-bullet-bg: #fff;
$current-entries-bullet-border: $default-text-color;
$current-entries-bullet-text: $current-entries-bullet-border;
$current-entries-text: $current-entries-bullet-border;

$past-entries-bullet-bg: #fff;
$past-entries-bullet-border: #9b91b8;
$past-entries-bullet-text: $past-entries-bullet-border;
$past-entries-text: $past-entries-bullet-border;

$timeline-bg-current: #9b91b8;
$timeline-bg-past: #9b91b8;
$timeline-bg-deadline: #9b91b8;

$deadline-entries-bullet-bg: $light-pink;
$deadline-entries-bullet-border: $primary-pink;
$deadline-entries-bullet-text: $default-text-color;
$deadline-entries-text: $primary-pink;

.timeline__title {
  h1 {
    font-size: 1.25rem;
  }
  h2 {
    font-size: 1.15rem;
  }
  h3 {
    font-size: 1.1rem;
  }
  h1,
  h2,
  h3 {
    margin-bottom: 1rem;
  }

  p {
    margin-bottom: 1.6rem;
  }
}
.timeline {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 3rem;
  z-index: 1;
  position: relative;
}

.timeline__item {
  .pdf {
    background-color: #fff;
  }
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  padding: 0 1rem;
  border: 0px;
  transition: all ease-out 0.25s;

  &:after {
    content: "";
    display: block;
    height: 100%;
    width: 2px;
    background-color: $default-text-color;
    position: absolute;
    left: calc(2.63rem - 1px);

    @include size-tablet {
      left: calc(2.38rem - 1px);
    }
  }

  &:first-of-type {
    &:after {
      top: 3rem;
    }
  }
  &:last-of-type {
    &:after {
      height: 2rem;
    }
  }
  &.past {
    .timeline__item_date_box,
    .timeline__item_title,
    .timeline__item_bullet {
      border-color: $past-entries-bullet-border;
      color: $past-entries-bullet-text;
    }
    &:after {
      background-color: $past-entries-text;
    }
    &:last-of-type {
      &:after {
        background-color: $past-entries-text;
      }
    }
  }
  &.current {
    .timeline__item_bullet {
      background-color: $primary-blue;
      color: white;
    }
  }
  &.open {
    background-color: $default-panel-background-color;
    border-radius: 0.5rem;
    .timeline__item_title {
      &:after {
        background-image: url("/assets/icons/arrow_up_blue.svg");
      }
    }
  }

  &.deadline {
    .timeline__item_bullet {
      background-color: $deadline-entries-bullet-bg;
      color: $deadline-entries-bullet-text;
      border-color: $deadline-entries-bullet-border;
    }
    .timeline__item_title {
      color: $deadline-entries-text;
    }
  }

  @include size-tablet {
    padding-left: 1rem;
  }
}

.timeline__item_range {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 0;
  border: 0px;
  background-color: transparent;

  //  background-image: url("/images/primary.gif");
  background-position: calc(2rem - 1px);
  background-repeat: repeat-y;
  transition: all ease-out 0.25s;
  &.past {
    .timeline__item_date_box,
    .timeline__item_title,
    .timeline__item_bullet {
      border-color: $past-entries-text;
      color: $past-entries-text;
    }
    .pdf {
      background-color: #fff;
    }
  }

  .timeline__item_bullet {
    height: 4.75rem;
  }
  .timeline__item_date_box,
  .timeline__item_title {
    h3 {
      margin: 0;
      margin-bottom: 0.5rem;
    }
    align-items: flex-start;
    flex-direction: column;
    &:after {
      background-position: 0.25rem right;
    }
  }

  &.open {
    background-color: $default-panel-background-color;
    border-radius: 0.5rem;
    .timeline__item_title {
      &:after {
        background-image: url("/assets/icons/arrow_up_blue.svg");
      }
    }
  }
  &.current {
    .timeline__item_bullet {
      background-color: $primary-blue;
      color: white;
    }
  }
  &:after {
    content: "";
    display: block;
    height: 100%;
    // top: 2rem;
    width: 2px;
    background-color: $current-entries-bullet-border;
    position: absolute;
    left: calc(1.63rem - 1px);

    @include size-tablet {
      left: calc(2.38rem - 1px);
    }
  }
  &:first-of-type {
    &:after {
      top: 2rem;
    }
  }

  &.past {
    &:after {
      background-color: $past-entries-text;
    }
  }
  @include size-tablet {
    padding-left: 1rem;
  }
}

.timeline > *:first-child {
  margin-top: 0;
}

.timeline__item_button {
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 2rem;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  @include size-tablet {
    padding: 2rem;
    padding-left: 0rem;
    padding-right: 0rem;
  }
}

.timeline__item_bullet {
  @include bullet(
    white,
    2px solid $current-entries-bullet-border,
    $current-entries-text
  );
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 2.75rem;
  height: 2.75rem;
  line-height: 2.75rem;
  border-radius: 1.37rem;
  font-weight: 600;
  margin-right: $default-margin;
  position: relative;
  z-index: 10;
  &:last-of-type {
    &:after {
      content: "";
      position: absolute;
      height: 2rem;
      width: 2px;
      background-color: transparent;
      bottom: calc(0px - 2rem);
    }
  }

  &.past {
    &:last-of-type {
      &:after {
        background-color: $past-entries-text;
      }
    }
  }
}

.timeline__item_date_box {
  display: block;
  margin-right: $default-margin;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 1rem;
  width: 10rem;
  color: $default-text-color;
}

.timeline__item_title {
  flex-grow: 1;
  display: flex;
  position: relative;
  flex-direction: column;
  color: $default-text-color;
  flex-shrink: 7;
  h3 {
    margin: 0;
    font-size: 1rem;
    font-weight: 600;
  }
  &:after {
    content: "";
    display: block;
    position: absolute;
    background-position: center;
    background-image: url("/assets/icons/arrow_down_blue.svg");
    background-repeat: no-repeat;
    background-color: $default-panel-background-color;
    border-radius: 0.25rem;
    height: 2.5rem;
    width: 2.5rem;
    top: auto;
    bottom: auto;
    right: 0;
  }
}

.timeline__item_content {
  margin-left: 3.75rem;
  margin-right: 1rem;
  margin-bottom: $default-margin;
  p {
    margin-bottom: 1rem;
  }
}

a.file,
.file-upload {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  border-radius: $default-border-radius-small;
  padding: 0.6rem 0.5rem;
  margin: 0.3rem 0;
  margin-bottom: 1rem;
  &:last-child {
    margin-bottom: 0;
  }
  text-decoration: none;
  color: #333;
  font-size: 0.9rem;

  .sign__and__text {
    display: flex;
    align-items: center;
    p {
      font-size: 0.9rem;
      color: $primary-blue;
      margin-bottom: 0;
      padding: 0.3rem 0;
    }
    img {
      width: 1.9rem;
      margin-right: 0.8rem;
    }
  }
}

.on_line_title {
  justify-content: center;
}
