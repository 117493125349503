.button-outline-red {
  background-color: white !important;
  color: red !important;
  border: solid 1px red !important;
}

.button-outline {
  background-color: white !important;
  color: #2f196b !important;
  border: solid 1px #2f196b !important;
}

.retract-button {
  width: auto !important;
  padding-left: 20px;
  padding-right: 20px;
}

.img-right {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  img {
    padding-left: 2rem;
  }
  h1 {
    font-size: 2rem;
  }
}

.regular {
  font-weight: normal;
}

#succesimage {
  float: right;
  max-width: 50%;
}
