@import "../../styles/variables.scss";

.newsletter__form {
  font-size: 0.875rem;

  .form-succes {
    color: $signin-valid;
  }

  .form-result {
    padding-bottom: 0.75rem;
  }

  .form-control {
    padding: 0.75rem 0.5rem;
    border-radius: 0.25rem;
  }

  .form-group {
    padding-bottom: 0.75rem;

    & > * {
      margin: 0;
    }

    p {
      margin: 0;
    }
  }

  .checkbox__container {
    display: flex;
    align-items: center;

    .checkmark {
      top: auto;
    }
  }

  .submit {
    font-size: 1rem;
  }
}
