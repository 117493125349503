@import "./shared/shared.scss";

/* React only */
@import "./signin/timeline-signin.scss";
@import "./signin/side-panel-right.scss";
@import "./signin/popups.scss";
@import "./infoblock.scss";
@import "./accountapply.scss";
@import "./content-image.scss";

html {
  scroll-padding-top: 150px;
  width: 100%;
}

#root {
  width: 100%;
  max-width: 100vw;
  height: 100%;
  min-height: 100vh;

  & > .page {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;

    & > .container {
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: auto;
    }
  }
}

.react-datepicker-wrapper {
  width: 100%;
}
