@import "../../styles/variables.scss";

.popup-content {
  background: #FFFFFF;
  border: 1px solid #2F196B;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: .8rem;
  color: #2F196B;
  padding: 1rem;
  width: 600px !important;

  @media (max-width: #{$tablet-width}) {
    width: 70% !important;
  }

  h4 {
    font-weight: 700;
    font-size: .8rem;
  }

  p + p {
    margin-top: .4rem;
  }
}

.popup-arrow {
  stroke: #2F196B;
}

.popup-trigger {
  display: inline-flex;
  align-items: center;
  text-decoration: underline;

  &:hover {
    cursor: pointer;
  }

  img {
    margin-left: .1rem;
  }
}