@import "../../styles/variables.scss";

.container {
  margin-left: auto;
  margin-right: auto;
}

.button-qna {
  height: 3rem;
  background-color: #fff;
  border: 1px solid $primary;
  border-radius: $default-border-radius-extra-small;
  padding-left: 1rem;
  padding-right: 1rem;
  color: $primary;
  cursor: pointer;
}

.header-login {
  .logo-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.projects-overview-container {
  h1 {
    font-size: 2rem;
    margin-bottom: 4.8rem;
  }

  .about-content {
    margin-top: 2.625rem;
  }

  .projects-overview {
    .item {
      margin-bottom: 3.5rem;

      h3 {
        font-size: 1.5rem;
        margin-bottom: 1.875rem;
      }

      // hide divider after last item
      &:last-of-type {
        .divider {
          display: none;
        }
      }
    }

    .project-List {
      .answerbox {
        background-color: $default-panel-background-color;
        border-radius: 0.5rem;
        display: block;
        position: relative;
        margin: 0 0 1rem 0;

        & :hover {
          background-color: $primary;
          color: white;
          cursor: pointer;
          border-radius: 0.5rem;
        }
      }

      .answerbox__content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-size: 1rem;
        padding: 1rem 2rem;
      }

      .answerbox__open {
        display: flex;
        align-items: center;
        flex-grow: 1;
      }

      // .is_open {
      //   display: flex;
      //   justify-content: flex-start;
      //   align-items: center;

      //   .icon {
      //     background-color: $checkbox-icon;
      //     width: 1.5rem;
      //     height: 1.5rem;
      //     border-radius: 100%;
      //     display: flex;
      //     justify-content: center;
      //     align-items: center;
      //     margin-right: 0.75rem;
      //     padding: 0.5rem;

      //     img {
      //       width: 0.9rem;
      //     }
      //   }
      //   .icon-open {
      //     background-color: #fff;
      //     width: 1.5rem;
      //     height: 1.5rem;
      //     border-radius: 100%;
      //     display: flex;
      //     justify-content: center;
      //     align-items: center;
      //     margin-right: 0.75rem;
      //     padding: 0.5rem;

      //     img {
      //       width: 0.9rem;
      //     }
      //   }
      // }

      .open_answer {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        h4 {
          display: inline;
        }

        .icon {
          background-color: $checkbox-icon;
          width: 1.5rem;
          height: 1.5rem;
          border-radius: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 0.75rem;
          padding: 0.5rem;

          &:hover {
            background-color: none;
          }

          img {
            width: 0.9rem;
          }
        }

        .icon-open {
          background-color: #fff;
          width: 1.5rem;
          height: 1.5rem;
          border-radius: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 0.75rem;
          padding: 0.5rem;

          img {
            width: 0.9rem;
          }
        }
      }

      // .answerbox__published {
      //   display: flex;
      //   justify-content: flex-start;
      //   align-items: center;
      // }
      // .is_published {
      //   display: flex;
      //   justify-content: center;
      //   align-items: center;

      //   .icon {
      //     background-color: $checkbox-icon;
      //     width: 1.5rem;
      //     height: 1.5rem;
      //     border-radius: 100%;
      //     display: flex;
      //     justify-content: center;
      //     align-items: center;
      //     margin-right: 0.75rem;
      //     padding: 0.5rem;

      //     img {
      //       width: 0.9rem;
      //     }
      //   }
      // }

      .answerbox__text {
        margin-right: $default-margin;
        width: 20%;

        h4 {
          display: block;
          font-weight: 600;
          padding-bottom: 0;
        }

        span {
          display: block;
          font-weight: 400;
        }
      }
    }
  }
}

.half-width-container {
  width: 100%;

  // background-color: yellow;
  &:first-of-type {
    margin-bottom: 2rem;
  }

  @include size-tablet {
    width: 48.3%;

    &:first-of-type {
      margin-bottom: 0;
    }
  }
}

.half-width-content {
  width: 100%;

  @include size-tablet {
    width: 48.3%;
  }
}

.question-container {
  .question-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;

    h2 {
      font-size: 1.5rem;
      margin-bottom: 0;
    }

    .question-not-selected {
      background-color: #fff;
      border: 1px solid $primary;
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 0.75rem;
      padding: 0.5rem;
    }

    .question-selected {
      background-color: $checkbox-icon;
      border: none;

      img {
        width: 1.4rem;
      }
    }
  }

  h4 {
    line-height: 2.5rem;
    font-size: 1rem;
  }

  .question-specs-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 2rem;

    @include size-tablet {
      flex-direction: row;
      margin-bottom: 3.75rem;
    }

    .question-specs {
      display: flex;
      align-items: flex-end;

      ul {
        list-style: none;
        line-height: 2.5rem;
      }
    }
  }

  .question-content-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 3.75rem;

    @include size-tablet {
      flex-direction: row;
    }

    textarea {
      width: 100%;
      scroll-behavior: auto;
      background-color: $grey-light;
      color: $primary;
      border: none;
      outline: none;
      min-height: 15rem;
      padding: 0.8rem 1.25rem;
      margin-bottom: 0.5rem;

      @include size-tablet {
        margin-bottom: 2.5rem;
      }
    }

    .button-container {
      text-align: end;
      margin-top: 1.875rem;

      .button {
        border: none;
        height: 3rem;
        padding-left: 2rem;
        padding-right: 2rem;
        margin-left: 1rem;
        border-radius: $default-border-radius-extra-small;
        cursor: pointer;
      }

      .save-publish-button {
        background-color: $primary;
        color: #fff;
      }

      .change-button {
        background-color: #fff;
        color: $primary;
        border: 1px solid $primary;
      }
    }

    .chosen-categorie {
      border: 1px solid $primary;
      opacity: 0.32;
      border-radius: $default-border-radius-small;
      padding: 1.5rem;
      margin-bottom: 1.5rem;
    }
  }
}

.text-open span {
  text-decoration: none;
}
.text-open .open {
  word-spacing: 1rem;
}
