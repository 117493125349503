@import "../../styles/variables.scss";

.index__list__container {
  display: flex;
  flex-direction: column;

  .title-list {
    padding: 0.75rem 0;
    padding-left: 1rem;
    font-weight: 300;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:after {
      content: "";
      background: transparent url("/assets/icons/arrow_down_blue.svg") no-repeat;
      background-size: contain;
      height: 0.5rem;
      padding-right: 1.5rem;
      display: block;
    }
  }

  .index__list {
    width: 100%;
    list-style-type: none;
    font-size: 0.9rem;
    padding: 0 1rem;

    &.is-current-row {
      background-color: red;
      border-radius: 8px;
    }

    display: none;

    @include size-tablet {
      display: block;
      font-size: 1rem;
    }

    .button_apply {
      position: relative;
    }

    @include size-tablet {
      display: block;
    }

    li {
      margin-bottom: 1rem;
      margin-top: 1rem;

      a {
        color: $default-text-color;
        text-decoration: none;
        line-height: 0.75rem;
        font-weight: 300;
        font-size: 0.9rem;
      }

      &.is-current-row {
        a {
          // text-decoration: underline;
          font-weight: 600;
        }
      }
    }
  }
}
