@import "../../styles/variables.scss";

.nav-qna-container {
  background-color: #fff;

  box-shadow: 0px 0px 80px rgba(0, 0, 0, 0.16);

  .nav-qna {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 1.5rem;
    padding-left: 1rem;
    position: relative;
    font-size: 0.9rem;
  }

  .nav-qna-back {
    all: unset;
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    display: flex;
    align-items: center;

    &:before {
      content: "";
      background-image: url("/assets/icons/arrow_left_blue.svg");
      background-repeat: no-repeat;
      display: inline-block;
      width: 1rem;
      height: 10px;
    }
  }
}
