@import "../../styles/variables.scss";

.partner__logo {
  background-color: #fff;
  border-radius: $default-border-radius-small;
  position: absolute;
  top: 1.4rem;
  right: 1.5rem;
  display: flex;
  justify-content: center;
  width: max-content;
  max-width: 8rem;

  img {
    width: 100%;
    height: 100%;
    padding: 0.4rem 2rem;
    border-radius: 0;
  }
}