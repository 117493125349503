@import "../../styles/variables.scss";

.contact__wrapper {
  background-color: $theme-aanbesteding;
  padding: 2.5rem;
  border-radius: $default-border-radius-extra-small;

  .contact__form {
    padding-top: 1rem;

    .contact__fields {
      display: flex;
      gap: 0.5rem;

      * {
        width: 100%;
      }
    }

    .field {
      margin-bottom: 0.5rem;

      .checkbox__wrapper {
        margin-bottom: 0;
      }
    }

    input,
    textarea {
      border: none;
      width: 100%;
      border-radius: 0.125rem;
      padding: 0.8rem;
      margin-bottom: 0.2rem;
      border: 1px solid $white;
      color: $primary-blue;
      font-weight: 200;

      &:focus,
      &:active {
        outline: none;
        border: 1px solid $primary-lightblue;
      }
    }

    textarea {
      display: block;
    }

    .checkbox__container {
      .checkmark {
        top: 0.15rem;
      }
    }

    label {
      font-weight: 200;
      font-size: 0.9rem;
    }

    ::placeholder {
      color: $primary-blue;
    }

    .submit__form {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 3rem;
      background-color: $primary-blue;
      color: #ffffff;
      text-decoration: none;
      border-radius: $default-border-radius-extra-small;
      font-size: 1.125rem;
      font-weight: 500;

      img {
        margin: 0 1rem;
        width: 1.375rem;
      }

      &:disabled {
        opacity: 0.7;
        cursor: default;
      }
    }

    button + .form-error {
      margin-top: 0.3rem;
    }

    .form-succes {
      color: $signin-valid;
      margin-top: 0.3rem;
    }
  }
}
