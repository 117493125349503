@import "../../styles/variables.scss";

.form-control {
  width: 100%;
  background-color: $grey-input;
  margin-bottom: 1rem;
  border: none;
  outline: none;
  padding: 1rem 0.5rem;
}

.login-button {
  width: 100%;
  height: 3rem;
  border: none;
  background-color: $primary-blue;
  color: #fff;
  border-radius: $default-border-radius-extra-small;
  cursor: pointer;
}

.invalidCredentials {
  padding: 1rem 0;
  color: #c00;
}

.hidden {
  display: none;
}

.form-container > div:not(:last-child) {
  margin-bottom: 2.75rem;
}

.account-content {
  margin-top: 4.3rem;
  margin-left: auto;
  margin-right: auto;

  .col1 {
    a {
      color: $primary-blue;
      font-weight: 300;
      text-decoration: none;

      display: block;
      padding: 0.75rem 0;
      padding-left: 1rem;
      font-weight: 300;
    }
    a.active {
      font-weight: 600;
      background-color: $theme-aanbesteding;
      border-radius: $default-border-radius-small;
    }
    &.sidebar-profile {
      // background-color: green;
      @include size-tablet {
        padding-right: 2rem;
      }
    }
    .index-item {
      .item-link {
        &.company-checkmark {
          display: flex;
          justify-content: space-between;
          align-items: center;
          &:after {
            content: "";
            background: transparent url("/assets/icons/vink-white.svg") no-repeat;
            background-color: $checkbox-icon;
            background-size: 40%;
            background-position: 50%;
            height: 1.5rem;
            width: 1.5rem;
            border-radius: 1rem;
            margin-right: 1rem;
            display: block;
          }
        }
      }
    }
  }
}
