@import "../../styles/variables.scss";

.projectlist__collapsed__button {
  @include button-type;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  align-items: center;
  border: 0px;
  border-radius: $default-border-radius-extra-small;
  text-decoration: none;
  background-color: $white;
  margin: 2rem auto;

  .grey {
    background-color: $grey-light;
    border-radius: $default-border-radius-extra-small;
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
