@import "../../styles/variables.scss";

.container {
  padding-left: 1rem;
  padding-right: 1rem;

  @include size-mobile {
    width: 100%;
  }

  @include size-tablet {
    width: 90%;
    margin: auto;
  }

  @include size-large {
    width: 90%;
    max-width: 78rem;
    margin: auto;
  }
}

.header__margin {
  margin-bottom: 1.75rem;
}

.header__share {
  display: flex;
  padding: 0.5rem 0;
  justify-content: flex-end;
}

.header__content__wrapper.project,
.header__content__wrapper.partner,
.header__content__wrapper.loket {
  border-radius: 1.5rem 1.5rem 0 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  min-height: 16rem;
  width: 100%;
  box-shadow: 0px 10px 64px rgba(0, 0, 0, 0.25);
  padding: 0px;
}

.header__content__wrapper.blogs > .header__content > .header__content__inner {
  padding: 0 0 0 0;
}

.header__content {
  display: flex;
  flex-direction: column-reverse;
  padding: 0;
  border-radius: 1.5rem 1.5rem 0 0;

  @include size-tablet {
    &:first {
      min-height: 16rem;
    }

    flex-direction: row;
  }

  .header__content__inner {
    padding: 2rem;
    padding-left: 2rem;

    flex-grow: 1;

    div {
      max-width: 100%;
    }

    p {
      margin-bottom: 1.5rem;
    }

    h2 {
      font-size: 1.75rem;
    }

    h4 {
      font-weight: 600;
    }

    @include size-tablet {
      padding: 3rem 6.75rem;
      margin-bottom: 0;
    }
  }

  .header__thumbnail {
    flex-shrink: 0;
    border-radius: 1.5rem 1.5rem 0 0;
    background-size: cover;
    background-position: bottom center;
    border-radius: 1.5rem 1.5rem 0 0;

    .header__thumbnail__image {
      display: flex;
      object-fit: cover;
      width: 100%;
      height: 12rem;
      border-radius: 1.5rem 1.5rem 0 0;
      @include size-tablet {
        width: 100%;
        height: 100%;
        border-radius: 0 1.5rem 0 0;
      }
    }
    @include size-tablet {
      border-radius: 0 1.5rem 0 0;
      flex-shrink: 0.9;
      max-width: 33%;
    }
    @include size-desktop {
      flex-shrink: 0;
    }
  }

  .header__thumbnail.partner {
    padding: 3rem;

    img {
      object-fit: contain;
    }
  }
}
