@import "../../styles/variables.scss";

.header__home {
  display: flex;
  flex-wrap: wrap-reverse;
  justify-content: center;
  align-items: center;

  @include size-desktop {
    flex-wrap: nowrap;
    justify-content: space-between;
  }

  .header__home__title {
    h1 {
      font-weight: normal;
      font-size: 2rem;

      @include size-desktop {
        font-size: 2.5rem;
        white-space: nowrap;
      }
    }

    p {
      font-size: 1.25rem;
      margin: 1rem 0;
    }

    .header__home_cta {
      padding-top: 2rem;

      .cta_description {
        font-weight: 600;
        font-size: 1.25rem;
        padding-bottom: 0.5rem;
      }

      .cta__memo > p {
        font-size: 1rem;
      }

      .cta__contact {
        display: flex;
        align-items: center;
        gap: 1rem;

        p {
          font-size: 1rem;

          span {
            display: inline-block;
            opacity: 0.5;
            margin-left: 1rem;
          }
        }
      }

      .img__block {
        flex-shrink: 0;
        position: relative;
        width: 64px;
        height: 64px;

        img {
          z-index: 300;
          height: 100%;
          width: 100%;
          object-fit: cover;
          border-radius: 50%;
        }
      }

      .cta__buttons {
        display: flex;
        gap: 1rem;
        flex-wrap: wrap;
      }

      .button__cta {
        @include button-type;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 0px;
        border-radius: $default-border-radius-extra-small;
        padding: 0.6rem 2.5rem;
        margin: 0 0 0 0;
        color: white;
        text-decoration: none;
        background-color: $primary-blue;
        font-size: 1.2rem;
        font-weight: 400;
        text-align: center;

        @media (max-width: #{$mobile-width}) {
          width: 100%;
        }

        .icon {
          margin-left: -1rem;
          margin-right: 1rem;
        }

        &.secondary {
          background-color: white;
          color: $primary-blue;
          border: 1px solid rgba($primary-blue, 0.32);

          &:hover,
          &:focus {
            border-color: rgba($primary-blue, 0.7);
          }
        }
      }
    }
  }

  .header__home__image {
    flex-shrink: 1;
    display: flex;
    justify-content: center;

    img {
      height: 100%;
      width: 100%;
      max-width: 70%;

      @include size-desktop {
        max-width: 100%;
      }
    }
  }
}

.header__home__content {
  padding: 3rem 0;

  .header__home__content__inner {
    display: flex;
    flex-direction: column;

    h1 {
      font-weight: 300;
      margin-top: 2.5rem;
      font-size: 1.75rem;
      margin-bottom: 1.5rem;
      span {
        display: inline-block;
      }
    }
    p {
      width: 100%;
      strong {
        font-weight: 500;
      }

      &:first-child {
        font-size: 1.5rem;
        margin-bottom: 1rem;
      }

      &:last-child {
        margin-bottom: 2rem;
      }
    }

    ul {
      display: flex;
      flex-direction: column;
      list-style-type: none;

      li {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        min-width: 50%;
        flex-grow: 1;
        height: 3rem;
        padding-left: 3.8rem;
        margin: 1rem 0;
        font-size: 1.25rem;
        font-weight: 500;
        background-repeat: no-repeat;
        background-position-y: center;

        @include size-tablet {
          margin: 0.7rem 0;
        }
      }
    }
  }
}
