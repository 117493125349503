@import "../../styles/variables.scss";

.button__apply {
  @include button-type;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0px;
  border-radius: $default-border-radius-extra-small;
  padding-left: 1rem;
  padding-right: 1rem;
  height: 3rem;
  margin-bottom: 1rem;
  color: white;
  text-decoration: none;
  background-color: $primary-blue;
  margin-right: 1rem;
  @include size-tablet {
    margin-right: 2rem;
  }

}

.delete__modal_buttons {
  margin-top: 2.6rem;
  display: flex;
  flex-direction: column-reverse;
  gap: .6rem;

  @include size-tablet {
    flex-direction: row;
  }

  .button__apply {
    cursor: pointer;
    justify-content: center;
    align-items: center;
    border: 0px;
    border-radius: $default-panel-radius-small;
    height: 3rem;
    text-decoration: none;
    width: 100%;
    color: #fff;
    background-color: $primary-blue;
    margin: 0.4rem 0 0 0;

    &.back {
      background-color: transparent;
      text-decoration: underline;
      color: $primary-blue;
      border: 1px solid $primary-blue;
    }
  }
}
