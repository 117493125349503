@import "../../styles/shared/breakpoints";
@import "../../styles/shared/colors";
@import "../../styles/shared/typography";
@import "../../styles/shared/variables";

.header_sticky {
  z-index: 900;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  transition: top 0.5s ease-in-out;

  &.header-hidden {
    top: -6.4rem;
  }

  .header_container {
    z-index: 9000;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0.875rem 2rem;
    width: 100%;
    box-shadow: 0px 10px 64px rgba(0, 0, 0, 0.25);
    background-color: #fff;
    position: relative;
    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  div {
    display: flex;
    align-items: center;
    padding-bottom: 1rem;

    @include size-mobile {
      padding-bottom: 0;
    }

    img {
      display: block;
      max-height: 3rem;
      width: 3rem;
      margin-right: 2.625rem;
    }

    button {
      margin-bottom: 0;
      margin-right: 0;
      margin-left: 1rem;
    }
    h3 {
      margin-bottom: 0;
      margin-left: 1rem;
    }
  }
  .sticky-middle-content {
    display: none;
    padding-left: 1rem;
    max-width: 30%;
    @include size-desktop {
      display: block;
    }
  }
  .sticky-right-content {
    justify-content: flex-end;
    .text-right-side {
      justify-content: flex-end;
      div {
        max-width: 55%;
      }
    }
    div {
      h3 {
        font-size: 1rem;
        margin: 0;
      }
    }
    .percentage {
      margin-left: 2rem;
    }

    .button_apply {
      @include button-type;
      display: flex;
      position: relative;
      justify-content: center;
      align-items: center;
      border: 0px;
      border-radius: $default-panel-radius-small;
      padding-left: 1rem;
      padding-right: 1rem;
      margin-bottom: 1rem;
      color: white;
      text-decoration: none;
      background-color: $primary-blue;
      margin-right: 1rem;
      margin-top: 2.5rem;

      @include size-tablet {
        margin-right: 2rem;
      }
    }
  }

  p {
    strong {
      font-weight: 600;
    }
  }
  .sticky_breadcrumbs {
    font-weight: 600;
    text-decoration: none;
    &:before {
      content: url("/assets/icons/arrow_left_blue.svg");
      padding-right: 0.3rem;
    }
  }
}

.fullpage {
  scroll-behavior: smooth;
}
