@import "../../styles/variables.scss";

.header-login {
  background-color: #fff;
  width: 100%;

  img {
    display: block;
    margin-top: 2.3rem;
    margin-bottom: 2.3rem;
    width: 10rem;
  }

  h1 {
    font-size: 2.5rem;
    margin-bottom: 4.625rem;
    line-height: 2.25rem;
  }
  h2 {
    font-size: 1.5rem;
    line-height: 2.25rem;
  }

  .form-group {
    margin-bottom: 1rem;
  }

  .form-control {
    width: 100%;
    background-color: $grey-input;

    margin-bottom: 0;
    border: none;
    height: 3rem;
    outline: none;
    padding: 0 0.5rem;
  }
  .login-button {
    width: 100%;
    height: 3rem;
    border: none;
    background-color: $primary-blue;
    color: #fff;
    border-radius: $default-border-radius-extra-small;
    cursor: pointer;
  }
}

