@import "../../styles/variables.scss";

.page > .container {
  margin: 0 0 0 0;

  @include size-mobile {
    margin: 0 auto;
  }

  .block {
    padding: 2rem;
  }
}
