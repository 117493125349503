@import "../../styles/variables.scss";

.footer {
  border-top: 0.4rem solid rgba($primary-blue, 0.05);
  width: 100%;

  .footer__columns {
    padding: 3rem 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 2rem;

    @media (max-width: #{$mobile-width}) {
      flex-direction: column;
      padding: 1.5rem 1rem;
    }

    & > div {
      display: flex;
      flex-direction: column;
      flex-basis: 30%;
      gap: 1rem;

      .site_logo {
        width: 10rem;
      }

      .about_text {
        font-size: 0.875rem;
        text-decoration: none;
        opacity: 0.66;
      }
    }

    .footer__menu {
      & > a {
        display: flex;
        align-items: center;
        gap: 0.3rem;
      }
    }

    .footer__linkedin a {
      color: #0068be;
      display: flex;
      align-items: center;
      column-gap: 0.5rem;
    }
  }

  .footer__subfooter {
    background-color: rgba($primary-blue, 0.05);
    padding: 1rem 0;
    font-size: 0.875rem;

    .container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      @media (max-width: #{$mobile-width}) {
        flex-direction: column;
        align-items: center;
      }

      .footer__subfooter_menu {
        display: flex;
        justify-content: center;
        column-gap: 2rem;

        a {
          white-space: nowrap;
          font-weight: 400;
        }

        @media (max-width: #{$mobile-width}) {
          flex-direction: column;
          align-items: center;
          padding-bottom: 1rem;

          a {
            line-height: 2rem;
          }
        }
      }

      .footer__copyright {
        opacity: 0.66;
        display: flex;
        justify-content: center;
        gap: 0.2rem;
      }
    }
  }
}
