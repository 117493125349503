@mixin popup-wrapper() {
  background-color: #fff;
  box-shadow: 0px 0px 80px rgba(0, 0, 0, 0.16);
  width: 100%;
}

.popup-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  background: rgba(18, 18, 18, 0.16);
  width: 100%;
  height: 100%;
  z-index: 99999;
  display: none;
  &.active {
    display: block;
  }
  .help-popup {
    @include popup-wrapper();
    padding: 2.5rem;
    float: right;
    overflow-y: auto;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      width: 0;
    }
    @include size-mobile {
      width: 38rem;
    }

    .text-only-button {
      margin-bottom: 2.3125rem;
    }
    h1 {
      font-size: 1.75rem;
      line-height: 2.25rem;
      margin-bottom: 1rem;
    }
    p {
      margin-bottom: 2.4375rem;
    }
    .help-item-topic {
      h3 {
        line-height: 2.25rem;
        margin-bottom: 0.5rem;
      }
      .vraag-antwoord {
        display: flex;
        background-color: $grey-light;
        border-radius: $default-border-radius-small;
        padding: 1.25rem 1.5rem;

        .text {
          flex-grow: 1;
          p {
            margin-bottom: 1rem;
          }
        }
        img {
          object-fit: contain;
          width: unset;
        }
      }
      a {
        word-break: break-all;
      }
      img {
        width: 100%;
      }
    }
  }
  .divider {
    margin-bottom: 2.375rem;
    margin-top: 2.375rem;
  }

  .check-popup {
    @include popup-wrapper();
    border-radius: $default-border-radius-extra-small;
    margin-left: auto;
    margin-right: auto;
    height: 100vh;
    padding: 1rem 2.5rem;
    padding-right: 1.5rem;
    position: relative;
    display: flex;
    flex-direction: column;

    .text-only-button {
      margin-bottom: 2rem;
    }
    .check-buttons {
      display: flex;
      justify-content: space-between;
      button {
        &:first-of-type {
          margin-right: 0.75rem;
        }
        &:last-of-type {
          margin-left: 0.75rem;
        }
      }
      &.bottom-page-space {
        margin-bottom: 4.6875rem;
      }
    }
    .head {
      padding-right: 1.125rem;

      h1 {
        font-weight: 300;
        margin-bottom: 0.625rem;
      }
      p {
        margin-bottom: 1.5rem;
      }

      .divider {
        margin-bottom: 0;
      }
    }
    .check-content-wrapper {
      flex-grow: 1;
      position: relative;
      .check-content {
        padding-right: 1.125rem;
        position: absolute;
        overflow: auto;
        bottom: 0;
        top: 0;
        right: 0;
        left: 0;
        scrollbar-color: $grey-light transparent;

        /* custom scrollbar for safari browser */
        &::-webkit-scrollbar {
          width: 10px;
          border-radius: 10px;
        }
        &::-webkit-scrollbar-track {
          background-color: transparent;
        }
        &::-webkit-scrollbar-thumb {
          background-color: $grey-light;
          border-radius: 10px;
        }

        h1 {
          margin-top: 2.1875rem;
          margin-bottom: 1.875rem;
        }
        .check-item {
          .item-content {
            display: flex;

            .timeline__item_bullet {
              min-width: 2.75rem;
            }
            .item-text {
              margin-left: 0.25rem;
              margin-top: 0.55rem;
              flex-grow: 1;
              h3,
              p {
                margin-bottom: 1.25rem;
              }
              .upload-doc {
                img {
                  display: inline-block;
                  max-width: 1.875rem;
                  vertical-align: middle;
                }
                p {
                  display: inline-block;
                  vertical-align: middle;
                  padding-left: 0.8125rem;
                  margin-bottom: 0;
                }
              }
            }
            .wijzig-button {
              margin-top: 0.75rem;
            }
          }
        }
      }
    }

    @include size-mobile {
      width: 80%;
      margin-top: 5rem;
      margin-bottom: 5rem;
      padding: 1rem 3.625rem;
      padding-right: 1.5rem;
      height: calc(100vh - 10rem);
      .head {
        padding-right: 2.125rem;
      }
      .check-content-wrapper .check-content {
        padding-right: 2.125rem;
      }
    }
    @include size-tablet {
      width: 70%;
      padding: 1rem 5.625rem;
      padding-right: 1.5rem;
      .head {
        padding-right: 4.125rem;
      }
      .check-content-wrapper .check-content {
        padding-right: 4.125rem;
      }
    }
    @include size-desktop {
      width: 70%;
      padding: 1rem 6.625rem;
      padding-right: 1.5rem;
      .head {
        padding-right: 5.125rem;
      }
      .check-content-wrapper .check-content {
        padding-right: 5.125rem;
      }
    }
    @include size-large {
      width: 60%;
      max-width: 50rem;
    }
    @media (max-height: 500px) {
      width: 100%;
      margin-top: 0;
      margin-bottom: 0;
      padding: 1rem 3.625rem;
      padding-right: 1.5rem;
      height: 100vh;
      // max-width: unset;
      .head {
        padding-right: 2.125rem;
      }
      .check-content-wrapper .check-content {
        padding-right: 2.125rem;
      }
    }
  }
}
