@import "../../styles/variables.scss";

.timedToast {
  position: fixed;
  display: flex;
  flex-direction: row;
  width: 400px;
  background-color: $primary-blue;
  transition: all 250ms cubic-bezier(0.755, 0.05, 0.855, 0.06);
  right: 1rem;
  padding: 1rem;
  z-index: 999;
  border-radius: $default-panel-radius-small;
  color: white;

  @media (max-width: 450px) {
    width: calc(100vw - 25px);
  }
  a {
    color: white;
    text-decoration: underline;
  }

  .content {
    flex-grow: 1;
  }

  &.closed {
    bottom: -2.5rem;
    opacity: 0;
  }
  &.open {
    bottom: 1rem;
    opacity: 1;
  }

  .icon {
    background-color: transparent;
    width: 24px;
    height: 24px;
    border: 0;
    cursor: pointer;
    img {
      margin-left: 0.5rem;
    }
  }
}
